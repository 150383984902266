import React from 'react';
import { styled } from "linaria/react";
import { theme } from '../../Theme';

const Box = styled('div')`
    display: inline-block;
    height: 12px;
    width: 12px;
    background: transparent;
    border: 1px solid ${theme.colors.lightgrey};
    margin-right: 10px;
`;

const FlatCheckBox = (props) => {
    const isChecked = props.isChecked

    return (
        <Box style={
            isChecked ? { background: `${theme.colors.lightgrey}`}: {}}>
        </Box>
    )
};


export default FlatCheckBox;