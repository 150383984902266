import React from 'react';

const LipscoreBadge = ( ) => {
  return (
    <div className="lipscore-real-reviews-badge-container">
      <a href="https://lipscore.com/about-real-reviews" target="_blank" rel="nofollow" className="lipscore-real-reviews-badge-link lipscore-real-reviews-badge-absolute ">
        <svg viewBox="0 0 2500 400" width="92px" height="19px" className="lipscore-real-reviews-badge lipscore-review-text">
          <g transform="translate(-130, -260) scale(1.1)">
            <g transform="matrix(3.39797,0,0,3.39797,291.17,391.627)">
                <path d="M0,-3.393L14.973,-3.393C16.903,-3.393 18.566,-4.307 19.964,-6.138C21.362,-7.968 22.06,-10.314 22.06,-13.176C22.06,-16.103 21.262,-18.432 19.665,-20.163C18.067,-21.893 16.336,-22.759 14.474,-22.759L0,-22.759L0,-3.393ZM-16.371,33.74L-16.371,-37.133L15.572,-37.133C18.899,-37.133 21.976,-36.434 24.806,-35.037C27.633,-33.639 30.079,-31.808 32.142,-29.547C34.204,-27.283 35.818,-24.721 36.983,-21.86C38.147,-18.998 38.731,-16.103 38.731,-13.176C38.731,-10.98 38.464,-8.867 37.932,-6.838C37.398,-4.806 36.634,-2.894 35.636,-1.098C34.638,0.699 33.423,2.313 31.992,3.744C30.561,5.176 28.948,6.389 27.151,7.388L42.723,33.74L24.256,33.74L10.681,10.881L0,10.881L0,33.74L-16.371,33.74Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,572.235,434.03)">
                <path d="M0,-9.883C-0.334,-13.21 -1.532,-15.838 -3.593,-17.769C-5.657,-19.698 -8.22,-20.664 -11.28,-20.664C-14.409,-20.664 -16.987,-19.681 -19.016,-17.719C-21.047,-15.755 -22.227,-13.143 -22.56,-9.883L0,-9.883ZM-11.08,22.26C-15.472,22.26 -19.415,21.543 -22.909,20.113C-26.403,18.683 -29.364,16.736 -31.793,14.273C-34.223,11.812 -36.07,8.967 -37.333,5.739C-38.598,2.512 -39.229,-0.864 -39.229,-4.393C-39.229,-8.186 -38.598,-11.763 -37.333,-15.124C-36.07,-18.483 -34.238,-21.428 -31.843,-23.958C-29.447,-26.486 -26.503,-28.482 -23.009,-29.947C-19.515,-31.41 -15.539,-32.143 -11.08,-32.143C-6.622,-32.143 -2.663,-31.41 0.799,-29.947C4.258,-28.482 7.187,-26.503 9.583,-24.008C11.979,-21.512 13.791,-18.632 15.023,-15.373C16.254,-12.111 16.87,-8.685 16.87,-5.091C16.87,-4.158 16.835,-3.245 16.77,-2.347C16.703,-1.448 16.603,-0.665 16.47,-0.001L-22.26,-0.001C-21.995,3.527 -20.713,6.206 -18.417,8.035C-16.121,9.866 -13.51,10.78 -10.581,10.78C-8.253,10.78 -6.007,10.215 -3.843,9.083C-1.682,7.952 -0.234,6.422 0.499,4.492L14.075,8.285C12.011,12.412 8.817,15.771 4.492,18.366C0.165,20.962 -5.026,22.26 -11.08,22.26" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,732.87,363.818)">
                <path d="M0,31.443C1.73,31.443 3.409,31.143 5.041,30.545C6.671,29.945 8.018,29.147 9.084,28.149C10.614,26.951 11.38,25.688 11.38,24.356L11.38,19.365C9.982,18.832 8.467,18.416 6.838,18.117C5.206,17.817 3.693,17.668 2.296,17.668C-0.633,17.668 -3.045,18.334 -4.941,19.665C-6.838,20.996 -7.786,22.726 -7.786,24.855C-7.786,26.785 -7.055,28.365 -5.59,29.596C-4.127,30.828 -2.263,31.443 0,31.443M-4.692,42.923C-7.287,42.923 -9.7,42.506 -11.929,41.675C-14.159,40.843 -16.071,39.678 -17.668,38.181C-19.265,36.684 -20.53,34.921 -21.462,32.89C-22.394,30.861 -22.859,28.648 -22.859,26.252C-22.859,23.657 -22.31,21.295 -21.212,19.165C-20.114,17.036 -18.567,15.206 -16.57,13.675C-14.574,12.145 -12.212,10.947 -9.483,10.081C-6.755,9.217 -3.728,8.783 -0.399,8.783C1.797,8.783 3.926,8.967 5.989,9.333C8.051,9.699 9.848,10.249 11.38,10.98L11.38,8.584C11.38,2.529 7.886,-0.5 0.898,-0.5C-1.964,-0.5 -4.692,-0.016 -7.287,0.947C-9.882,1.914 -12.612,3.361 -15.472,5.29L-20.164,-4.692C-16.704,-6.954 -13.111,-8.651 -9.383,-9.784C-5.657,-10.913 -1.697,-11.48 2.495,-11.48C10.414,-11.48 16.536,-9.616 20.863,-5.89C25.188,-2.162 27.351,3.293 27.351,10.48L27.351,23.957C27.351,25.554 27.6,26.668 28.1,27.3C28.599,27.933 29.48,28.315 30.745,28.449L30.745,41.924C29.348,42.256 28.082,42.474 26.952,42.573C25.819,42.673 24.788,42.723 23.857,42.723C20.863,42.723 18.599,42.139 17.069,40.976C15.538,39.812 14.574,38.165 14.174,36.035L13.875,33.639C11.545,36.633 8.767,38.93 5.54,40.527C2.312,42.124 -1.098,42.923 -4.692,42.923" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,876.009,509.328)">
                <path d="M0,-73.768L15.971,-73.768L15.971,-19.565C15.971,-15.572 17.668,-13.575 21.062,-13.575C21.861,-13.575 22.742,-13.691 23.707,-13.925C24.671,-14.157 25.554,-14.507 26.353,-14.973L28.449,-2.995C26.452,-2.062 24.156,-1.33 21.561,-0.799C18.966,-0.267 16.536,0 14.274,0C9.682,0 6.154,-1.197 3.693,-3.593C1.23,-5.989 0,-9.449 0,-13.975L0,-73.768Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1038.2,379.074)">
                <path d="M0,3.995L21.462,3.995C23.324,3.995 25.005,3.596 26.502,2.797C28,1.998 29.28,0.917 30.346,-0.447C31.41,-1.811 32.242,-3.392 32.841,-5.189C33.44,-6.986 33.74,-8.848 33.74,-10.779C33.74,-12.775 33.391,-14.655 32.691,-16.419C31.993,-18.181 31.06,-19.746 29.897,-21.11C28.731,-22.474 27.367,-23.539 25.804,-24.305C24.24,-25.069 22.593,-25.453 20.863,-25.453L0,-25.453L0,3.995ZM-8.984,37.434L-8.984,-33.439L21.362,-33.439C24.489,-33.439 27.367,-32.789 29.996,-31.492C32.624,-30.194 34.888,-28.463 36.784,-26.302C38.681,-24.138 40.161,-21.71 41.226,-19.014C42.29,-16.32 42.823,-13.574 42.823,-10.779C42.823,-8.316 42.474,-5.937 41.775,-3.642C41.077,-1.346 40.078,0.734 38.781,2.597C37.483,4.46 35.919,6.058 34.089,7.389C32.258,8.721 30.212,9.684 27.95,10.284L45.119,37.434L34.938,37.434L18.767,11.98L0,11.98L0,37.434L-8.984,37.434Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1344.29,429.957)">
                <path d="M0,-7.087C-0.2,-9.548 -0.816,-11.829 -1.847,-13.925C-2.88,-16.021 -4.177,-17.801 -5.74,-19.265C-7.305,-20.728 -9.118,-21.876 -11.18,-22.709C-13.244,-23.54 -15.439,-23.957 -17.768,-23.957C-20.098,-23.957 -22.294,-23.54 -24.357,-22.709C-26.419,-21.876 -28.234,-20.713 -29.797,-19.215C-31.36,-17.718 -32.625,-15.937 -33.589,-13.874C-34.555,-11.812 -35.137,-9.548 -35.336,-7.087L0,-7.087ZM-17.868,23.458C-21.795,23.458 -25.389,22.742 -28.648,21.311C-31.91,19.882 -34.704,17.935 -37.033,15.472C-39.364,13.011 -41.176,10.132 -42.473,6.838C-43.771,3.544 -44.42,0.067 -44.42,-3.594C-44.42,-7.253 -43.771,-10.713 -42.473,-13.975C-41.176,-17.235 -39.346,-20.097 -36.983,-22.559C-34.622,-25.021 -31.81,-26.967 -28.549,-28.399C-25.289,-29.829 -21.695,-30.545 -17.768,-30.545C-13.842,-30.545 -10.266,-29.812 -7.037,-28.349C-3.81,-26.884 -1.066,-24.938 1.198,-22.509C3.46,-20.08 5.206,-17.252 6.438,-14.025C7.669,-10.796 8.285,-7.452 8.285,-3.993C8.285,-3.259 8.268,-2.595 8.235,-1.996C8.201,-1.397 8.151,-0.931 8.085,-0.598L-35.137,-0.598C-34.937,1.997 -34.338,4.359 -33.34,6.489C-32.341,8.62 -31.044,10.449 -29.447,11.979C-27.85,13.51 -26.037,14.708 -24.007,15.573C-21.978,16.438 -19.832,16.87 -17.568,16.87C-15.971,16.87 -14.408,16.655 -12.877,16.221C-11.347,15.789 -9.916,15.207 -8.585,14.474C-7.254,13.743 -6.073,12.844 -5.041,11.779C-4.01,10.716 -3.229,9.518 -2.696,8.186L4.891,10.282C4.026,12.212 2.845,13.975 1.347,15.573C-0.149,17.169 -1.881,18.552 -3.843,19.715C-5.807,20.88 -7.985,21.795 -10.381,22.46C-12.777,23.124 -15.273,23.458 -17.868,23.458" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1442.99,329.216)">
                <path d="M0,52.107L-20.562,0L-11.479,0L4.992,44.421L21.562,0L29.947,0L9.384,52.107L0,52.107Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,-830.472,-814.3)">
                <path d="M705.129,336.529L713.913,336.529L713.913,388.636L705.129,388.636L705.129,336.529ZM705.129,315.767L713.913,315.767L713.913,326.748L705.129,326.748L705.129,315.767Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1776.17,429.957)">
                <path d="M0,-7.087C-0.2,-9.548 -0.816,-11.829 -1.847,-13.925C-2.88,-16.021 -4.177,-17.801 -5.74,-19.265C-7.305,-20.728 -9.118,-21.876 -11.18,-22.709C-13.244,-23.54 -15.439,-23.957 -17.768,-23.957C-20.098,-23.957 -22.295,-23.54 -24.357,-22.709C-26.42,-21.876 -28.234,-20.713 -29.797,-19.215C-31.361,-17.718 -32.626,-15.937 -33.59,-13.874C-34.555,-11.812 -35.137,-9.548 -35.336,-7.087L0,-7.087ZM-17.868,23.458C-21.795,23.458 -25.389,22.742 -28.648,21.311C-31.91,19.882 -34.705,17.935 -37.033,15.472C-39.364,13.011 -41.176,10.132 -42.474,6.838C-43.772,3.544 -44.42,0.067 -44.42,-3.594C-44.42,-7.253 -43.772,-10.713 -42.474,-13.975C-41.176,-17.235 -39.347,-20.097 -36.983,-22.559C-34.622,-25.021 -31.81,-26.967 -28.549,-28.399C-25.289,-29.829 -21.695,-30.545 -17.768,-30.545C-13.842,-30.545 -10.266,-29.812 -7.037,-28.349C-3.81,-26.884 -1.066,-24.938 1.198,-22.509C3.46,-20.08 5.206,-17.252 6.438,-14.025C7.669,-10.796 8.285,-7.452 8.285,-3.993C8.285,-3.259 8.268,-2.595 8.235,-1.996C8.201,-1.397 8.151,-0.931 8.085,-0.598L-35.137,-0.598C-34.937,1.997 -34.339,4.359 -33.341,6.489C-32.342,8.62 -31.044,10.449 -29.447,11.979C-27.85,13.51 -26.038,14.708 -24.007,15.573C-21.978,16.438 -19.832,16.87 -17.569,16.87C-15.972,16.87 -14.409,16.655 -12.877,16.221C-11.347,15.789 -9.917,15.207 -8.585,14.474C-7.254,13.743 -6.073,12.844 -5.041,11.779C-4.01,10.716 -3.229,9.518 -2.696,8.186L4.891,10.282C4.026,12.212 2.845,13.975 1.347,15.573C-0.149,17.169 -1.881,18.552 -3.843,19.715C-5.807,20.88 -7.985,21.795 -10.381,22.46C-12.777,23.124 -15.273,23.458 -17.868,23.458" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2045.86,506.275)">
                <path d="M0,-52.107L8.484,-52.107L-13.675,0L-20.963,0L-31.444,-25.454L-41.825,0L-49.112,0L-71.272,-52.107L-62.887,-52.107L-44.92,-8.685L-36.136,-30.346L-45.119,-52.006L-37.833,-52.006L-31.444,-35.337L-25.056,-52.006L-17.768,-52.006L-26.653,-30.346L-17.868,-8.685L0,-52.107Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2153.1,326.164)">
                <path d="M0,54.004C-4.192,54.004 -8.32,53.355 -12.378,52.057C-16.437,50.76 -19.931,48.88 -22.859,46.417L-19.066,40.528C-16.005,42.858 -12.944,44.605 -9.882,45.769C-6.822,46.933 -3.593,47.515 -0.199,47.515C3.594,47.515 6.604,46.766 8.835,45.27C11.063,43.772 12.179,41.661 12.179,38.93C12.179,37.667 11.879,36.585 11.28,35.686C10.681,34.788 9.783,34.006 8.585,33.341C7.387,32.676 5.856,32.077 3.993,31.543C2.13,31.012 -0.035,30.446 -2.495,29.847C-5.624,29.048 -8.32,28.284 -10.58,27.551C-12.844,26.82 -14.708,25.954 -16.171,24.956C-17.635,23.957 -18.701,22.759 -19.366,21.362C-20.031,19.964 -20.363,18.235 -20.363,16.171C-20.363,13.576 -19.848,11.265 -18.816,9.234C-17.786,7.205 -16.37,5.507 -14.574,4.143C-12.777,2.78 -10.665,1.748 -8.235,1.049C-5.807,0.35 -3.195,0 -0.399,0C3.527,0 7.188,0.634 10.581,1.897C13.975,3.162 16.835,4.827 19.165,6.888L15.173,12.179C12.977,10.249 10.514,8.802 7.786,7.836C5.057,6.873 2.262,6.389 -0.598,6.389C-3.793,6.389 -6.505,7.072 -8.734,8.436C-10.965,9.8 -12.078,11.979 -12.078,14.973C-12.078,16.171 -11.863,17.17 -11.429,17.969C-10.997,18.767 -10.282,19.465 -9.283,20.065C-8.285,20.663 -7.005,21.197 -5.44,21.662C-3.877,22.128 -1.997,22.627 0.2,23.159C3.659,23.957 6.671,24.773 9.233,25.604C11.795,26.438 13.925,27.418 15.622,28.549C17.319,29.682 18.582,31.012 19.415,32.542C20.246,34.074 20.663,35.936 20.663,38.132C20.663,42.991 18.8,46.851 15.074,49.711C11.346,52.573 6.322,54.004 0,54.004" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1489.34,547.33)">
                <path d="M0,23.027C1.018,23.027 1.958,22.819 2.819,22.401C3.681,21.983 4.425,21.422 5.052,20.716C5.679,20.011 6.161,19.209 6.501,18.308C6.84,17.407 7.01,16.461 7.01,15.469C7.01,14.477 6.847,13.517 6.521,12.59C6.193,11.664 5.737,10.847 5.149,10.143C4.562,9.438 3.857,8.87 3.035,8.439C2.213,8.008 1.305,7.793 0.313,7.793C-0.418,7.793 -1.11,7.923 -1.763,8.184C-2.416,8.445 -3.022,8.791 -3.584,9.222C-4.146,9.653 -4.642,10.156 -5.072,10.73C-5.503,11.304 -5.875,11.918 -6.188,12.57L-6.188,18.484C-6.058,19.137 -5.777,19.738 -5.346,20.286C-4.915,20.834 -4.406,21.311 -3.818,21.715C-3.231,22.121 -2.604,22.44 -1.938,22.674C-1.273,22.91 -0.627,23.027 0,23.027M0.861,26.004C-0.731,26.004 -2.181,25.612 -3.485,24.829C-4.792,24.046 -5.823,23.027 -6.58,21.774L-6.58,25.612L-9.635,25.612L-9.635,-2.977L-6.188,-2.977L-6.188,9.242C-5.326,7.91 -4.27,6.84 -3.016,6.03C-1.763,5.221 -0.301,4.817 1.37,4.817C2.78,4.817 4.046,5.117 5.169,5.717C6.291,6.318 7.251,7.108 8.048,8.087C8.844,9.066 9.458,10.201 9.889,11.494C10.319,12.786 10.534,14.111 10.534,15.469C10.534,16.905 10.286,18.262 9.79,19.542C9.294,20.821 8.615,21.937 7.754,22.89C6.893,23.843 5.867,24.6 4.68,25.162C3.491,25.722 2.219,26.004 0.861,26.004" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1540.31,575.936)">
                <path d="M0,22.755C0.339,22.807 0.679,22.846 1.018,22.872C1.357,22.898 1.618,22.911 1.802,22.911C2.114,22.911 2.395,22.839 2.644,22.695C2.892,22.552 3.139,22.272 3.388,21.854C3.635,21.436 3.916,20.856 4.229,20.111C4.543,19.367 4.935,18.394 5.404,17.193L-3.251,-3.25L0.313,-3.25L7.284,14.1L13.629,-3.25L16.918,-3.25L6.971,22.559C6.604,23.473 5.998,24.276 5.149,24.967C4.301,25.659 3.133,26.005 1.645,26.005C1.41,26.005 1.168,25.991 0.92,25.966C0.672,25.94 0.365,25.888 0,25.81L0,22.755Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1641.02,529.674)">
                <path d="M0,30.808L0,0L3.905,0L3.905,27.336L21.088,27.336L21.088,30.808L0,30.808Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,-830.472,-418.154)">
                <path d="M752.077,287.098L755.895,287.098L755.895,309.748L752.077,309.748L752.077,287.098ZM752.077,278.072L755.895,278.072L755.895,282.845L752.077,282.845L752.077,278.072Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1796.42,597.204)">
                <path d="M0,8.07C1.129,8.07 2.148,7.832 3.06,7.354C3.971,6.878 4.752,6.241 5.402,5.445C6.054,4.65 6.553,3.747 6.899,2.733C7.247,1.721 7.42,0.68 7.42,-0.391C7.42,-1.519 7.218,-2.596 6.812,-3.623C6.407,-4.65 5.858,-5.547 5.164,-6.314C4.47,-7.08 3.652,-7.687 2.713,-8.136C1.772,-8.584 0.752,-8.808 -0.347,-8.808C-1.041,-8.808 -1.757,-8.671 -2.494,-8.396C-3.232,-8.121 -3.927,-7.76 -4.577,-7.312C-5.229,-6.863 -5.792,-6.328 -6.27,-5.706C-6.747,-5.084 -7.058,-4.426 -7.202,-3.732L-7.202,2.733C-6.885,3.486 -6.479,4.187 -5.987,4.838C-5.496,5.489 -4.932,6.052 -4.295,6.53C-3.659,7.008 -2.979,7.383 -2.256,7.658C-1.533,7.933 -0.78,8.07 0,8.07M1.172,11.368C-0.709,11.368 -2.364,10.906 -3.796,9.979C-5.229,9.054 -6.363,7.883 -7.202,6.465L-7.202,20.176L-11.021,20.176L-11.021,-11.716L-7.637,-11.716L-7.637,-7.464C-6.74,-8.852 -5.575,-9.972 -4.144,-10.826C-2.712,-11.679 -1.128,-12.106 0.608,-12.106C2.17,-12.106 3.602,-11.787 4.903,-11.152C6.205,-10.515 7.334,-9.655 8.288,-8.57C9.243,-7.485 9.987,-6.234 10.522,-4.817C11.058,-3.399 11.325,-1.924 11.325,-0.391C11.325,1.23 11.079,2.755 10.588,4.187C10.096,5.619 9.401,6.863 8.505,7.919C7.608,8.975 6.537,9.813 5.294,10.435C4.05,11.057 2.676,11.368 1.172,11.368" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1877.81,556.068)">
                <path d="M0,23.474C-1.822,23.474 -3.616,23.193 -5.381,22.628C-7.146,22.064 -8.664,21.247 -9.937,20.176L-8.288,17.617C-6.958,18.629 -5.627,19.389 -4.296,19.895C-2.966,20.401 -1.562,20.654 -0.087,20.654C1.562,20.654 2.87,20.328 3.84,19.677C4.809,19.027 5.294,18.109 5.294,16.922C5.294,16.373 5.163,15.903 4.903,15.512C4.643,15.121 4.252,14.783 3.731,14.493C3.211,14.203 2.545,13.943 1.735,13.711C0.925,13.48 -0.015,13.234 -1.085,12.974C-2.445,12.627 -3.616,12.294 -4.6,11.976C-5.583,11.658 -6.394,11.282 -7.029,10.848C-7.666,10.413 -8.129,9.893 -8.418,9.286C-8.708,8.678 -8.852,7.926 -8.852,7.029C-8.852,5.901 -8.628,4.896 -8.18,4.014C-7.731,3.131 -7.116,2.394 -6.335,1.801C-5.555,1.208 -4.637,0.759 -3.58,0.455C-2.524,0.152 -1.389,0 -0.174,0C1.533,0 3.124,0.275 4.6,0.824C6.074,1.374 7.318,2.098 8.331,2.994L6.596,5.293C5.641,4.455 4.57,3.826 3.385,3.406C2.198,2.987 0.982,2.777 -0.261,2.777C-1.649,2.777 -2.828,3.074 -3.797,3.666C-4.767,4.26 -5.251,5.207 -5.251,6.509C-5.251,7.029 -5.157,7.463 -4.969,7.81C-4.78,8.157 -4.47,8.461 -4.035,8.722C-3.602,8.982 -3.045,9.214 -2.365,9.416C-1.686,9.619 -0.868,9.835 0.087,10.066C1.591,10.413 2.899,10.768 4.014,11.13C5.127,11.492 6.053,11.918 6.79,12.41C7.528,12.902 8.077,13.48 8.439,14.145C8.801,14.811 8.981,15.62 8.981,16.576C8.981,18.687 8.172,20.365 6.552,21.609C4.932,22.853 2.748,23.474 0,23.474" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,1918.5,596.17)">
                <path d="M0,-0.13C0,-1.72 0.274,-3.225 0.824,-4.642C1.373,-6.059 2.154,-7.296 3.167,-8.352C4.18,-9.408 5.387,-10.247 6.79,-10.869C8.193,-11.491 9.763,-11.802 11.498,-11.802C13.755,-11.802 15.699,-11.303 17.334,-10.305C18.968,-9.307 20.205,-7.968 21.044,-6.291L17.312,-5.12C16.733,-6.19 15.924,-7.022 14.883,-7.615C13.841,-8.207 12.684,-8.504 11.411,-8.504C10.37,-8.504 9.387,-8.294 8.461,-7.875C7.535,-7.455 6.739,-6.87 6.074,-6.118C5.408,-5.365 4.881,-4.483 4.49,-3.471C4.1,-2.458 3.904,-1.345 3.904,-0.13C3.904,1.057 4.106,2.17 4.513,3.211C4.917,4.252 5.459,5.158 6.14,5.923C6.818,6.69 7.614,7.29 8.526,7.724C9.438,8.158 10.413,8.374 11.455,8.374C12.12,8.374 12.778,8.282 13.429,8.093C14.08,7.905 14.673,7.652 15.208,7.333C15.743,7.015 16.213,6.647 16.618,6.227C17.023,5.808 17.312,5.352 17.486,4.86L21.218,5.989C20.87,6.828 20.386,7.594 19.764,8.288C19.142,8.983 18.411,9.576 17.573,10.067C16.733,10.559 15.801,10.95 14.774,11.239C13.747,11.528 12.67,11.672 11.542,11.672C9.834,11.672 8.272,11.354 6.855,10.718C5.437,10.082 4.223,9.229 3.211,8.158C2.197,7.088 1.41,5.836 0.846,4.405C0.281,2.973 0,1.461 0,-0.13" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2012.71,595.729)">
                <path d="M0,0.13C0,1.317 0.195,2.416 0.586,3.428C0.977,4.441 1.512,5.322 2.191,6.074C2.871,6.827 3.667,7.42 4.578,7.854C5.489,8.287 6.466,8.504 7.507,8.504C8.549,8.504 9.524,8.287 10.436,7.854C11.348,7.42 12.15,6.819 12.844,6.053C13.538,5.287 14.081,4.39 14.472,3.362C14.862,2.336 15.057,1.23 15.057,0.043C15.057,-1.113 14.862,-2.205 14.472,-3.233C14.081,-4.259 13.538,-5.156 12.844,-5.923C12.15,-6.689 11.348,-7.29 10.436,-7.724C9.524,-8.158 8.549,-8.374 7.507,-8.374C6.466,-8.374 5.489,-8.15 4.578,-7.702C3.667,-7.253 2.871,-6.646 2.191,-5.88C1.512,-5.113 0.977,-4.216 0.586,-3.189C0.195,-2.162 0,-1.056 0,0.13M7.507,11.802C5.8,11.802 4.245,11.484 2.843,10.848C1.439,10.212 0.239,9.359 -0.759,8.287C-1.757,7.218 -2.531,5.974 -3.08,4.556C-3.63,3.139 -3.904,1.649 -3.904,0.087C-3.904,-1.504 -3.623,-3.008 -3.059,-4.426C-2.494,-5.843 -1.714,-7.087 -0.716,-8.158C0.282,-9.227 1.482,-10.081 2.886,-10.718C4.289,-11.353 5.829,-11.672 7.507,-11.672C9.185,-11.672 10.732,-11.353 12.15,-10.718C13.566,-10.081 14.775,-9.227 15.773,-8.158C16.771,-7.087 17.552,-5.843 18.116,-4.426C18.681,-3.008 18.962,-1.504 18.962,0.087C18.962,1.649 18.687,3.139 18.138,4.556C17.588,5.974 16.807,7.218 15.795,8.287C14.782,9.359 13.574,10.212 12.172,10.848C10.769,11.484 9.214,11.802 7.507,11.802" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2133.62,622.414)">
                <path d="M0,-15.794C-1.909,-15.735 -3.595,-15.244 -5.055,-14.318C-6.516,-13.393 -7.55,-12.106 -8.157,-10.457L-8.157,3.515L-11.976,3.515L-11.976,-19.135L-8.418,-19.135L-8.418,-13.885C-7.637,-15.447 -6.61,-16.712 -5.337,-17.681C-4.064,-18.65 -2.705,-19.192 -1.259,-19.309L-0.521,-19.309C-0.318,-19.309 -0.146,-19.294 0,-19.265L0,-15.794Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2205.13,601.181)">
                <path d="M0,-3.08C-0.087,-4.15 -0.354,-5.141 -0.803,-6.052C-1.252,-6.963 -1.815,-7.737 -2.495,-8.373C-3.175,-9.01 -3.964,-9.509 -4.859,-9.871C-5.757,-10.232 -6.711,-10.413 -7.724,-10.413C-8.736,-10.413 -9.691,-10.232 -10.588,-9.871C-11.484,-9.509 -12.272,-9.003 -12.952,-8.352C-13.632,-7.701 -14.182,-6.927 -14.602,-6.031C-15.021,-5.134 -15.273,-4.15 -15.36,-3.08L0,-3.08ZM-7.767,10.197C-9.475,10.197 -11.036,9.887 -12.453,9.265C-13.871,8.643 -15.086,7.797 -16.098,6.727C-17.111,5.656 -17.898,4.405 -18.463,2.973C-19.027,1.541 -19.309,0.03 -19.309,-1.561C-19.309,-3.152 -19.027,-4.656 -18.463,-6.074C-17.898,-7.491 -17.104,-8.735 -16.076,-9.806C-15.05,-10.875 -13.827,-11.722 -12.41,-12.344C-10.993,-12.966 -9.431,-13.277 -7.724,-13.277C-6.018,-13.277 -4.463,-12.958 -3.06,-12.322C-1.656,-11.685 -0.463,-10.839 0.521,-9.784C1.504,-8.727 2.263,-7.498 2.799,-6.096C3.334,-4.692 3.602,-3.239 3.602,-1.735C3.602,-1.416 3.594,-1.127 3.58,-0.867C3.564,-0.607 3.543,-0.404 3.515,-0.26L-15.273,-0.26C-15.187,0.869 -14.927,1.896 -14.492,2.821C-14.059,3.747 -13.495,4.543 -12.801,5.208C-12.106,5.873 -11.318,6.395 -10.436,6.77C-9.554,7.146 -8.621,7.334 -7.637,7.334C-6.942,7.334 -6.264,7.24 -5.598,7.052C-4.933,6.864 -4.311,6.611 -3.731,6.292C-3.153,5.975 -2.641,5.584 -2.191,5.121C-1.743,4.658 -1.403,4.138 -1.172,3.559L2.126,4.47C1.75,5.31 1.236,6.075 0.586,6.77C-0.065,7.464 -0.817,8.065 -1.671,8.57C-2.524,9.077 -3.472,9.475 -4.513,9.764C-5.554,10.053 -6.639,10.197 -7.767,10.197" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2275.62,368.629)">
                <path d="M0,-9.776L-4.741,-9.776L-4.741,2.527L-7.603,2.527L-7.603,-9.776L-12.345,-9.776L-12.345,-12.303L0,-12.303L0,-9.776Z" style={{fillRule: 'nonzero'}}></path>
            </g>
            <g transform="matrix(3.39797,0,0,3.39797,2322.97,326.823)">
                <path d="M0,14.83L0,5.013L-3.822,12.323L-5.389,12.323L-9.212,5.013L-9.212,14.83L-12.094,14.83L-12.094,0L-9.003,0L-4.616,8.438L-0.187,0L2.883,0L2.883,14.83L0,14.83Z" style={{fillRule: 'nonzero'}}></path>
            </g>
          </g>
        </svg>
      </a>
    </div>
  )
};

export default LipscoreBadge;