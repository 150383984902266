import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from "linaria/react";
import SubMenuWrapper from './SubMenuWrapper';

import { theme } from "../../../Theme";

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      display: block;
      margin-right: 8px;
      ${theme.below.xl} {
        margin-right: 0.8rem;
      }
    }
  }
`;

const MainMenuItem = styled('li')`
  a {
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-size: 18px;
    padding: 25px 10px;
    letter-spacing: 0.08em;
    &.active {
      color: #000;
      font-weight: 400;
    }
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => props.setActiveCategory(cat)}
    >
      <CategoryLink
        // When following a category link, close the menu
        onClick={props.clearActiveCategories}
        category={cat}
      >
        {cat.name}
      </CategoryLink>
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
