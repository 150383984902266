import React, { useContext } from 'react';
import { styled } from "linaria/react";
import StaticMobileMenuFooter from '../Footer/StaticMobileMenuFooter';
import { Query } from 'react-apollo';
import MenuLinksRightQuery from './CategoryMenu/MenuLinksRight.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from "../../Theme";
import { ReactComponent as AngleDown } from '../../../svg/Angle-down.svg';

const StaticMenuContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ExtraLinkWrapper = styled('div')`
  svg {
    transform: rotate(-90deg);
  }
`;

const FooterLinksWrapper = styled('div')`
  padding: 0;
  > div {
    display: flex;
    flex-direction: column;
    a {
      color: ${theme.colors.lightgrey};
      align-items: center;
      padding: 5px 0;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-decoration: none;
      text-transform: capitalize;
    }
  }
`;

export const StaticMenuContent = ({ setMenuOpen = true }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <StaticMenuContentContainer>
        <FooterLinksWrapper>
          <StaticMobileMenuFooter
            closeMenu={() => {
              setMenuOpen(false);
            }}
          ></StaticMobileMenuFooter>

        </FooterLinksWrapper>
    </StaticMenuContentContainer>
  );
};

export const ExtraLink = ({ id }) => (
  <Query query={MenuLinksRightQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error!: ${error}`;
      return (
        <li>
          <ExtraLinkWrapper>
            <a href={data.category.primaryRoute?.path}>{data.category.name}</a>
            <AngleDown />
          </ExtraLinkWrapper>
        </li>
      );
    }}
  </Query>
);
