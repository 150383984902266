import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import React, { useState } from 'react';
import { styled } from "linaria/react";
import { css } from "linaria";
import flattenCountries from './flattenCountries';
import FlatCheckBox from '../../../CategoryPage/Filters/FlatCheckBox';

import { theme } from "../../../Theme";

const Country = styled('div')`
  height: 42px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  background-color: transparent;
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.75')};
  :hover {
    opacity: 1;
  }
`;

const CountryFlag = styled('img')`
  height: 1.3rem;
  width: 1.3rem;
  margin-right: 15px;
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid #2f80ed;
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: #2f80ed;
  }
`;

const StyledTitle = styled(Title)`
  color: #000;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #e8e8e8;
  padding: 2px 0 16px;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const hidden = css`
  display: none;
`;

const ButtonWrapper = styled('div')`
  button {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-transform: capatilize;

    &:focus {
      outline: none;
    }
  }

  ${CommitButton} {
    color: ${theme.colors.white};
    background: ${theme.colors.black};
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => {
  const [countries] = useState(flattenCountries(channels));
  const Comp = type;

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => (
        <Comp style={type === MiniSelector ? { width: '16rem' } : null}>
          <SelectorBody>
            <StyledTitle>{t('Select your country')}</StyledTitle>
            {countries.map(({ name, code, channel }) => (
              <Country
                key={channel.id + name}
                onClick={() => onSelect(channel.id, null, null, code)}
                isActive={
                  channel.id === selectedChannel.id &&
                  name === selectedChannel.country.name
                }
              >
                <FlatCheckBox
                  isChecked={
                    channel.id === selectedChannel.id &&
                    name === selectedChannel.country.name
                  }
                />
                <CountryFlag
                  src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                  alt="Country Flag"
                />
                {name}
              </Country>
            ))}
          </SelectorBody>
          <LangCurrWrapper className={hidden}>
            <ChecboxGroupContainer>
              <CheckboxGroup
                selectedItem={selectedChannel.language.name}
                items={languageItems}
                handleChange={onLanguageSelect}
                groupLabel={t('Language')}
              />
              <CheckboxGroup
                selectedItem={selectedChannel.currency.name}
                items={currencyItems}
                handleChange={onCurrencySelect}
                groupLabel={t('Currency')}
              />
            </ChecboxGroupContainer>
          </LangCurrWrapper>
          <ButtonWrapper>
            {hasChanged ? (
              <CommitButton
                onClick={() => {
                  updateChannel(selectedChannel);
                  hideTarget();
                }}
                text={t('Update')}
              />
            ) : (
              <CancelButton text={t('Close')} onClick={() => hideTarget()} />
            )}
          </ButtonWrapper>
        </Comp>
      )}
    />
  );
};

export default Selector;
