import uniqueId from '@jetshop/core/helpers/uniqueId';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import { css, cx } from "linaria";
import { toast } from 'react-toastify';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import NotifyModal from '../ProductPage/NotifyModal';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Product } from './Product';

const container = css`
  margin-top: 2em;
  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 400;
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: stretch;
    margin-right: -${theme.space[1]};
    margin-left: -${theme.space[1]};
    width: calc(100% + ${theme.space[1]}*2);
    /* overflow: hidden; */

    > * {
      line-height: 19px;
      min-width: 0;
      margin: ${theme.space[1]};
      width: calc(50% - ${theme.space[2]});
      ${theme.above.md} {
        width: calc(33.333% - ${theme.space[2]});
      }
      ${theme.above.lg} {
        width: calc(25% - ${theme.space[2]});
      }
    }

    button:focus {
      outline: none;
    }

    li .product-card .add-button {
      min-height: 47px;
      padding: 0.2rem 1rem;
    }
  }

  [data-valid] .product-card {
    border: 1px solid ${theme.colors.tablegrey};
  }
  [data-valid='valid'] .product-card {
    border: 1px solid ${theme.colors.tablegrey};
  }
  .product-card {
    a {
      margin: 0;
      border: 0;
    }
  }
  .product-card-detail,
  .product-card,
  .product-card a {
    background: white;
  }
  .product-card-detail {
    text-align: center;
  }

  .select-variant {
    margin: 0;
    padding: 1em;
    position: relative;
    z-index: 99;

    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.15em !important;
        text-transform: uppercase;
      }
      button:focus {
        outline: none;
      }
      ul {
        border: 1px solid ${theme.colors.tablegrey};
        border-top: 0;
        margin-top: -1px;
      }
      li {
        display: flex;
        justify-content: space-between;

        &:focus {
          outline: none;
        }
        &:hover {
          color: #000000;
          font-weight: 400;
          .out-of-stock-notification {
            font-weight: 400;
          }
        }
        svg {
          use {
            fill: #000000;
          }
        }
        .out-of-stock-notification {
          letter-spacing: 0;
          text-transform: capitalize;
        }
      }
    }
    button:focus {
      outline: none;
    }
  }

  .add-clear {
    display: flex;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    margin-top: 2rem;

    button {
      margin: 0;
      border-radius: 0;

      &.add-all {
        background: ${theme.colors.black};
        margin-top: 0 !important;
      }

      :disabled {
        color: ${theme.colors.black};
        background: ${theme.colors.lightgrey};
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .new-price,
  .old-price {
    display: inline-block;
  }
  .new-price {
    color: #ff0000;
  }
  .old-price {
    margin-left: 0.5em;
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

export function Favourites() {
  const { products, loading } = useProductListItems();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;
  const [modalData, setModalData] = useState();
  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        toast(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>,
          {
            toastId: uniqueId(),
            autoClose: 2000
          }
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('There are no products in your saved items list.')}</p>
      </MaxWidth>
    );
  }

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('Loading your saved items…')}</p>
      </MaxWidth>
    );
  }

  return <>
    <MaxWidth className={cx(container, loading ? 'loading' : null)}>
      <h1>{t('Saved items')}</h1>
      <ul className="product-grid">
        {products.map((product, index) => {
          return (
            <li
              key={product.variant?.articleNumber || product.articleNumber}
              data-valid={product.validation.status}
            >
              <Product product={product} setModalData={setModalData} />
            </li>
          );
        })}
      </ul>

      <div className="add-clear">
        <Button
          style={{ marginTop: '2em' }}
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {mutationLoading
            ? t('One moment…')
            : t(
                '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                { productCount: validItemCount }
              )}
        </Button>
        {/* <ClearList>{t('Clear list')}</ClearList> */}
      </div>
    </MaxWidth>
    <NotifyModal modalData={modalData} setModalData={setModalData} />
  </>;
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <Button secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}
