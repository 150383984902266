
const LipscoreCustomScript = () => {
  var checkInterval = setInterval(verifyLpscCustomDiv, 100);

  function verifyLpscCustomDiv() {
    if (document.getElementById('lpsc-custom-wrapper')) {
      apiCall();
      clearInterval(checkInterval);
    }
  }

  function apiCall() {
    const productId = document.querySelector('#lpsc-custom-wrapper')?.getAttribute('lpsc-product-id') || undefined;
    const lang = document.querySelector('#lpsc-custom-wrapper')?.getAttribute('lpsc-lang') || undefined;
    const types = productId == 'service_review' ? 'srw_b,srw_l' : 'rw_l,r,pa';

    fetch(`https://wapi.lipscore.com/initial_data/products/show?api_key=ecceac903845087ed75b8594&internal_id=${productId}&widgets=${types}&reviews_per_page=999&translate_to_lang=${lang}`)
    .then(response => response.json())
    .then(json => {
      if (document.querySelector('#lpsc-custom-attributes') && productId != 'service_review') {
      const votesTranslation = document.querySelector('#lpsc-custom-attributes').getAttribute('lpsc-custom-text-translation')
      let innerAttributes = ''
      json.product_attributes.forEach((attr) => {
        if (attr.votes < 10) {
          return;
        }
        let labels = attr.options_count == 5 ? [attr.labels[1], attr.labels[2], attr.labels[3]] : attr.labels;
        let tickPosition = Math.max((attr.summary_value - 1) * 25, 0)
        innerAttributes += `<div class="custom-attribute-summary-block">
        <div class="custom-attribute-summary-name">${attr.caption} - ${votesTranslation.replace('0000', attr.votes)}</div>
        <div class="custom-attribute-summary-wrap">
          <div class="custom-attribute-summary-labels">
            <div class="custom-attribute-label" style="text-align: left;">${labels[0]}</div>
            <div class="custom-attribute-label" style="text-align: center;">${labels[1]}</div>
            <div class="custom-attribute-label" style="text-align: right;">${labels[2]}</div>
          </div>
          <div class="custom-attribute-tick-wrap">
            <div class="custom-attribute-tick-filler" style="width: ${tickPosition}%;"></div>
          </div>
          <div class="custom-attribute-tick-wrap">
            <div class="custom-attribute-tick" style="left: ${tickPosition}%;"></div>
          </div>
        </div>
        </div>`;
      })
      document.querySelector('#lpsc-custom-attributes').innerHTML = innerAttributes
      }
      const score = json.lipscore / 2.0
      document.querySelector('.custom-badge-score').innerHTML = `${score.toFixed(1)}/5`
      document.querySelector('.custom-badge-text').textContent = document.querySelector('.custom-badge-text').textContent.replace('0000', json.review_count)
      
      let innerBadge = ''
      const halfStar = 'https://cdn-icons-png.flaticon.com/512/59/59419.png'
      const noneStar = 'https://cdn-icons-png.flaticon.com/128/2956/2956792.png'
      const fullStar = 'https://cdn-icons-png.flaticon.com/128/1828/1828961.png'
  
      for (let i = 1; i <= 5; i++) {
        if (score >= i) {
          innerBadge += `<img src="${fullStar}">`
        } else if (score <= i - 1) {
          innerBadge += `<img src="${noneStar}">`
        } else {
          innerBadge += `<img src="${halfStar}">`
        }
      }
  
      document.querySelector('.custom-badge-stars').innerHTML = innerBadge
  
      let innerList = '';
      const revList = document.querySelector('#lpsc-custom-list')
  
      const setReviewStars = (score) => {
        let result = '';
        if (Number.isInteger(score) && score != 0) {
          for (let i = 1; i <= 5; i++) {
            result += `<img src="${score / 2 >= i ? fullStar : noneStar}">`
          }
        }
        return result;
      }
  
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`;
      }
  
      const reviewAttributes = (reviewAttrs) => {
        let result = '';
        reviewAttrs.forEach(attr => {
          result += `<p class="custom-review-product-attr">${attr.caption}: ${attr.labels.options_count === 5 ? attr.labels[attr.value - 1] : attr.labels[Math.floor(attr.value / 2)]}</p>`
        });
        return result
      }
  
      const reviewBadgeBlock = `
      <div class="lipscore-review-purchase">
        <div class="lipscore-review-purchase-icon">
          <div class="lipscore-review-purchase-icon-badge">
            <span class="lipscore-visually-hidden">Verified</span>
          </div>
          <span>&nbsp;${document.querySelector('#lpsc-custom-wrapper').getAttribute('lpsc-custom-buyer')}</span>
        </div>
      </div>`
  
      const reviewProductTranslation = document.querySelector('#lpsc-custom-wrapper').getAttribute('lpsc-custom-product')
  
      json.reviews.forEach((rev, index) => {
        innerList += `<div class="custom-review ${index >= 10 ? 'unloaded-rev' : 'loaded-rev' }">
        <div class="custom-review-stars">${setReviewStars(rev.lipscore)}</div>
        <p class="custom-review-name">${rev.user.name}</p>
        <p class="custom-review-text">${rev.translated_text || rev.text}</p>
        <p class="custom-review-product">${reviewProductTranslation}: ${rev.product.name}</p>
        ${rev.attributes.length ? reviewAttributes(rev.attributes) : ''}
        <div class="custom-review-date-badge">${reviewBadgeBlock}<p class="custom-review-date">${formatDate(rev.created_at)}</p></div>
        </div>`
      });
  
      revList.innerHTML = innerList;
      
      const loadMoreFunc = () => {
        Array.from(document.querySelectorAll('#lpsc-custom-list > .unloaded-rev')).slice(0,10).forEach(r => r.classList.replace('unloaded-rev', 'loaded-rev'));
        if (document.querySelectorAll('#lpsc-custom-list > .unloaded-rev').length > 0) {
          document.querySelector('#lpsc-custom-load-more').addEventListener('click', (e) => {
            loadMoreFunc()
          }, { once: true });
        } else {
          document.querySelector('#lpsc-custom-load-more').style.display = 'none';
        }
      }
  
      if (revList.querySelectorAll('.unloaded-rev').length > 0) {
        document.querySelector('#lpsc-custom-load-more').style.display = 'block';
        document.querySelector('#lpsc-custom-load-more').addEventListener('click', (e) => {
          loadMoreFunc()
        }, { once: true });
      }
    })
    return null;
  }
}

export default LipscoreCustomScript;