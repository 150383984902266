
module.exports = {
  default: {
    colors: {
      white: "#FFFFFF",
      black: "#000000",
      main: "#000000",
      grey: "#878787",
      lightgrey: "#F1F1F1",
      lightgrey_previous: "#B9B9B9",
      tablegrey: "#F3F3F3",
      beige: "#f5f5dc",
      loadingBar: "#2f80ed",
      blue: "#2f80ed",
      red: "#cc5555",
      background: "#f4f4f4",
      darkgrey: "#333",
      mediumgrey: "#B9B9B9",
      highlight: "#2f80ed",
      activeGreen: '#23AD7A',
      iceblue: '#04B29F',
      creme: '#EAE5DC',
      primary: "#000000",
      lightBeige: '#e7e1d7',
      darkBeige: '#b5ae9d'    
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 400,
    },
    breakpoints: {
      xxs: "365px",
      xs: "25rem",
      xsm: "30rem",
      sm: "40rem",
      md: "50rem",
      lg: "64rem",
      xl: "80rem",
    },
    fontSizes: [
      "12px",
      "14px",
      "16px",
      "20px",
      "24px",
      "32px",
      "48px",
      "64px",
      "72px",
    ],
    space: [
      "0px",
      "8px",
      "16px",
      "24px",
      "32px",
      "40px",
      "48px",
      "56px",
      "64px",
    ],
    fonts: {
      primary: '"futura-pt", sans-serif'
    },
  },
};