import React from 'react';
import { styled } from "linaria/react";
import { ReactComponent as FooterLogo } from '../../../svg/Logo.svg';
import AirmeeLogo from './img/Partnerlogos_footer_Airmee_Beige.png';
import PostnordLogo from './img/Partnerlogos_footer_Postnord_Beige.png';
import UpsLogo from './img/Partnerlogos_footer_UPS_Beige.png';
import KlarnaLogo from './img/Partnerlogos_footer_Klarna_Beige.png';
import PaypalLogo from './img/Partnerlogos_footer_PayPal_Beige.png';
import VisaLogo from './img/Partnerlogos_footer_VISA_Beige.png';
import MasterLogo from './img/Partnerlogos_footer_Mastercard_Beige.png';
import { theme } from "../../Theme";
import FooterCopyright from './FooterCopyright';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${theme.colors.white}; 
  color: ${theme.colors.black};
  width: 100%;
  height: 70px;
  padding: 10px 85px;

  ${theme.below.md} {
    flex-direction: column-reverse;
    height: 104px;
    padding: 10px 20px 20px 20px;
  }
`;

const Logo = styled('div')`
  width: 90px;
  position: absolute;
  left: calc(50% - 45px);
  margin-top: 12px;
  ${theme.below.md} {
    width: 48px;
    align-self: end;
    position: absolute;
    left: auto;
  }
`;

const Partners = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img{
    width: 45px;
    margin: 0 0 0 4px;
    ${theme.below.sm} {
      width: 40px;
      margin: 0;
    }
  }
  ${theme.below.lg} {
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const FooterBottomArea = () => (
  <Wrapper>
      <FooterCopyright />      
      <Logo>
        <FooterLogo />
      </Logo>
      <Partners>
        <img src={AirmeeLogo} alt="Airmee"/>
        <img src={PostnordLogo} alt="Postnord"/>
        <img src={UpsLogo} alt="UPS"/>
        <img src={KlarnaLogo} alt="Klarna"/>
        <img src={PaypalLogo} alt="PayPal"/>
        <img src={VisaLogo} alt="VISA"/>
        <img src={MasterLogo} alt="Mastercard"/>
      </Partners>
  </Wrapper>
);

export default FooterBottomArea;
