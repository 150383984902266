import React from 'react';
import { Query } from 'react-apollo';
import footerQuery from './FooterQuery.gql';
import { styled } from 'linaria/react';
import { FooterLinks } from './FooterLinks';

const FooterLinksWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

const FooterLinkContent = ({isStaticMobileMenu}) => {
  return (
    <FooterLinksWrapper>
      <Query
        query={footerQuery}
        variables={{
          ids: ["df0c4773-02c2-4339-a3d9-7a5beb7f468e"],
        }}
      >
        {({ data, loading, error }) => {
          if (loading || error) {
            return null;
          }
          return data ? (
            <FooterLinks
              result={data}
              isStaticMobileMenu={isStaticMobileMenu}
            />
          ) : null;
        }}
      </Query>
      </FooterLinksWrapper>
  );
}

export default FooterLinkContent;
