import { css } from "linaria";

css`
  :global() {
    ${globals};
  }
`;

const fallbackStyles = `
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  visibility: visible;
`;

const globals = `
  html, body {
    height: 100%;
    font-family: "futura-pt", sans-serif;
    font-weight: 300!important;
    font-display: swap;
  }
  html, body, #root {
    min-height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  button {
    cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
    color: black;
  }
  ol, ul, h4, h3, h2, h1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p, dd {
    margin: 0;
    padding: 0;
  }
  a{
    color: black;
  }
  input {
    -webkit-appearance: none;
  }
  .ReactModalPortal{
    position:relative;
    z-index:22;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }
  body {
    ${fallbackStyles};
    -webkit-font-smoothing: antialiased;
  }
  /* 
  * apply a natural box layout model to all elements, but allowing components
  * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;