import t from '@jetshop/intl';
import React, { Fragment, useState } from 'react';
import { styled } from "linaria/react";
import ReactModal from 'react-modal';
import { ReactComponent as Cross } from '../../svg/Cross.svg';

import { theme } from "../Theme";

const InfoModalWrapper = styled(ReactModal)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
`;

const ModalInside = styled('div')`
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  background: #fff;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #EAE5DC;
  padding-top: 10px;
  box-shadow: 0 0 20px rgba(234, 229, 220, 0.8);

  svg.close-icon {
    position: absolute;
    top: 23px;
    right: 20px;
    width: 18px;
    height: 18px;
    color: black;
    use,
    g,
    path {
      fill: black;
    }
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    letter-spacing: .03em;
    font-weight: normal;
    padding: 0;
    padding-top
    3px;
  }
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    ${theme.below.lg} {
      font-size: 1rem;
    }
  }

  img {
    max-width: 100%;
  }
`;

const InfoLink = styled('button')`
  background: transparent;
  text-align: right;
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 0.03em;
  margin-left: 10px;
  &:focus {
    outline: none;
  }
`;

const Title = styled('h2')`
  padding-top: 2rem;
  margin-bottom: 5px;
  text-align: center;
`;

const Sizeguide = props => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <InfoLink
        className={`modalLink ${props.type ? props.type : null}`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {props.link && props.link}
        {props.children && props.children}
      </InfoLink>
      <InfoModalWrapper
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <ModalInside>
          <Cross
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Title>{t('Size guide')}</Title>
          <img src={props.sizeGuide} alt='' />
        </ModalInside>
      </InfoModalWrapper>
    </Fragment>
  );
};

export default Sizeguide;
