import React from 'react';
import { styled } from "linaria/react";
import InstagramLogo from './img/Partnerlogos_Footer_IG_Beige.png';
import FacebookLogo from './img/Partnerlogos_Footer_FB_beige.png';
import LinkedInLogo from './img/Partnerlogos_Footer_LInkedin_Beige.png';
import { theme } from "../../Theme";

const Wrapper = styled('section')`
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  a.social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 9px;
    img {
      width: 31px;
    }

  }

  color: ${theme.colors.black};
`;

const SocialLinks = () => (
  <Wrapper>
    <ul>
      <li>
        <a
          className="social-links"
          href="https://www.instagram.com/8848altitude/"
          target="blank"
        >
          <img src={InstagramLogo} alt="Instagram"/>
        </a>
      </li>
      <li>
        <a
          className="social-links"
          href="https://www.facebook.com/8848Altitude/"
          target="blank"
        >
          <img src={FacebookLogo} alt="Facebook"/>
        </a>
      </li>
      <li>
        <a
          className="social-links"
          href="https://www.linkedin.com/company/8848-altitude"
          target="blank"
        >
          <img src={LinkedInLogo} alt="LinkedIn"/>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
