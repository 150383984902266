import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import posed, { PoseGroup } from 'react-pose';
import { styled } from "linaria/react";
import { SearchField } from '@jetshop/ui/Search';
import AutocompleteQuery from './AutocompleteQuery.gql';
import SearchInput from './SearchInput.js';
import { Intl } from '@jetshop/intl';
import { theme } from "../../Theme";

const PosedSearchBarContainer = styled(
  posed('div')({
    enter: {
      marginTop: 0,
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-30px',
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)`
  position: absolute;
  border: 0;
  z-index: 99;
  max-width: 400px;
  right: 0;
  background: #f3f3f3;
  width: 100%;

  ${theme.below.lg} {
    position: relative;
    z-index: 0;
    max-width: 100%;
    height: 3rem;
  }

  input:focus {
    outline: none;
  }
  svg {
    position: absolute;
    right: -11px;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: visible;
    use {
      fill: #878787;
    }
  }
`;

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-right: 0;
  padding: 8px;
  ${theme.below.lg} {
    padding: 0;
    height: 100%;
  }
`;

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    display: flex;
    height: 2rem;
    width: 100% !important;
    justify-content: center;

    input {
      border: 0;
      background: #f3f3f3;
      height: 100%;
      width: 100%;
      padding: 0 1rem;
    }
  }
  ${SearchField.Cancel} {
    height: 100%;
    min-width: fit-content;
    width: fit-content;

    ${theme.below.lg} {
    }
  }
  ${SearchField.Flyout} {
    text-align: left;
    top: calc(100% + ((48px - 100%) / 2));
    left: -8px;
    width: 400px;
    border-right: 0;
    border-left: 0;
    ${theme.below.lg} {
      top: calc(100% + ((64px - 100%) / 2));
      width: calc(100% + 4rem + 16px);
      left: -8px;
      border-left: none;
      border-right: none;
    }
    > * {
      padding: 0.5rem;
    }
    & > div {
      padding: 1rem;
    }
    h2 {
      text-transform: uppercase;
      font-size: 0.8rem;
      padding: 0.5rem;
      letter-spacing: 0.1rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      padding: 0.5rem;
      display: block;
      text-decoration: none;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }
`;

const SearchBar = ({ searchOpen, setSearchOpen, hideTarget, setMenuOpen }) => (
  <Above breakpoint="lg">
    {matches =>
      matches ? (
        searchOpen && (
          <PoseGroup flipMove={true}>
            <PosedSearchBarContainer
              className={'search-bar'}
              key={'searchBarPosed'}
            >
              <InnerSearchBar>
                <StyledSearchField
                  autocompleteQuery={AutocompleteQuery}
                  onCancel={() => setSearchOpen(false)}
                />
              </InnerSearchBar>
            </PosedSearchBarContainer>
          </PoseGroup>
        )
      ) : (
        <PoseGroup>
          <PosedSearchBarContainer
            className={'search-bar'}
            key={'searchBarPosed'}
          >
            <InnerSearchBar>
              <Intl>
                {t => (
                  <SearchInput
                    autocompleteQuery={AutocompleteQuery}
                    onCancel={() => setSearchOpen(true)}
                    setMenuOpen={setMenuOpen}
                    placeholder={t('Search')}
                  />
                )}
              </Intl>
            </InnerSearchBar>
          </PosedSearchBarContainer>
        </PoseGroup>
      )
    }
  </Above>
);

export { SearchBar };
