import t, {useIntl} from '@jetshop/intl';
import React from 'react';
import { styled } from "linaria/react";
import Sizeguide from './SizeGuide';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from "../Theme";

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 0;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
  div.size-indicator {
    width: 100%;
  }

  ${theme.below.lg} {
    padding-bottom: 1rem;
    flex-direction: column;
  }
`;

const Section = styled('div')`
  flex: 1;
  margin-right: 2.5rem;
  position: relative;
  ${theme.below.lg} {
    margin-right: 0;
  }
  &.size-indicator-section {
    margin-right: 0;
  }

  > h3 {
    font-size: 16px;
    letter-spacing: 0.03em;
    font-weight: normal;
    padding-bottom: 0.5rem;
    ${theme.below.lg} {
      padding-bottom: 0;
    }
  }

  > div {
    margin-bottom: 0.7rem;
  }

  > div div {
    display: flex;
    margin-bottom: 2px;
    padding-bottom: 2px;
    h3 {
      flex: 2;
      font-size: 14px;
    }
    span {
      flex: 1;
      text-align: right;
      font-size: 14px;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    ${theme.below.lg} {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }
  }

  .modalLink {
  }

  .how-to-link {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #000;
    text-decoration: underline;
  }

  .fit-wrapper {
    display: flex;
    span {
      &:first-of-type {
        text-align: left;
      }
      &:nth-of-type(2) {
        text-align: center;
      }
      &:nth-of-type(3) {
        text-align: right;
      }
    }
  }
`;

const GraphBar = styled('div')`
  height: 4px;
  background: #f1f1f1;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: #b9b9b9;
    left: 0;
    height: 100%;
    width: ${props =>
      Math.round((parseInt(props.scale[0]) / parseInt(props.scale[1])) * 100)}%;
  }
`;

const GradingSection = props => {
  const tString = useIntl();
  return (
      <Wrapper className="grading-wrapper">
        {props.biking !== undefined ||
        props.casualWear !== undefined ||
        props.freerideSkiing !== undefined ||
        props.mountaineering !== undefined ||
        props.outdoorAndHiking !== undefined ||
        props.pisteSkiing !== undefined ||
        props.pisteSkiing !== undefined ||
        props.skiTouring !== undefined ||
        props.training !== undefined ||
        props.sizeGuide !== undefined || 
        props.breathability !== undefined ||
        props.durability !== undefined ||
        props.insulation !== undefined ||
        props.lightweight !== undefined ||
        props.waterproofness !== undefined ||
        props.windproofness !== undefined ? (
          <Section>
            {props.waterproofness !== undefined ? (
              <>
                {props.waterproofness.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.waterproofness.title}</h3>
                      <span>{props.waterproofness.stringValue}</span>
                    </div>
                    {props.waterproofness.stringValue !== undefined && (
                      <GraphBar
                        scale={props.waterproofness.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
            {props.windproofness !== undefined ? (
              <>
                {props.windproofness.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.windproofness.title}</h3>
                      <span>{props.windproofness.stringValue}</span>
                    </div>
                    {props.windproofness.stringValue !== undefined && (
                      <GraphBar
                        scale={props.windproofness.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
            {props.insulation !== undefined ? (
              <>
                {props.insulation.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.insulation.title}</h3>
                      <span>{props.insulation.stringValue}</span>
                    </div>
                    {props.insulation.stringValue !== undefined && (
                      <GraphBar
                        scale={props.insulation.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
            {props.breathability !== undefined ? (
              <>
                {props.breathability.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.breathability.title}</h3>
                      <span>{props.breathability.stringValue}</span>
                    </div>
                    {props.breathability.stringValue !== undefined && (
                      <GraphBar
                        scale={props.breathability.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
            {props.lightweight !== undefined ? (
              <>
                {props.lightweight.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.lightweight.title}</h3>
                      <span>{props.lightweight.stringValue}</span>
                    </div>
                    {props.lightweight.stringValue !== undefined && (
                      <GraphBar
                        scale={props.lightweight.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
            {props.durability !== undefined ? (
              <>
                {props.durability.stringValue.split('/')[0] !== '0' && (
                  <div>
                    <div>
                      <h3>{props.durability.title}</h3>
                      <span>{props.durability.stringValue}</span>
                    </div>
                    {props.durability.stringValue !== undefined && (
                      <GraphBar
                        scale={props.durability.stringValue.split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
          </Section>
        ) : null}
        {props.fitMeasure !== undefined ? (
          <div className='size-indicator' lpsc-custom-text-translation={tString('nReviews')}>
          <Section className='size-indicator-section'>
            {props.sizeGuide && (
              <Above breakpoint="lg">
                {matches =>
                  matches && (
                    <Sizeguide sizeGuide={props.sizeGuide.stringValue}>
                      <span className="how-to-link">{t('How we measure')}</span>
                    </Sizeguide>
                  )
                }
              </Above>
            )}
            {props.fitMeasure !== undefined ? (
              <>
                {props.fitMeasure.listValues[0].split('/')[0] !== '0' && (
                  <div>
                    <div className="fit-wrapper">
                      <span>{t('Runs small')}</span>
                      <span>{t('True to size')}</span>
                      <span>{t('Runs big')}</span>
                    </div>
                    {props.fitMeasure.listValues[0] !== undefined && (
                      <GraphBar
                        scale={props.fitMeasure.listValues[0].split('/')}
                      />
                    )}
                  </div>
                )}
              </>
            ) : null}
          </Section>
          </div>
        ) : null}
      </Wrapper>
  );
};

export default GradingSection;
