import React from 'react';
import { Query } from 'react-apollo';
import footerQuery from './FooterQuery.gql';
import { styled } from 'linaria/react';

const FooterCopyrightWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Rights = styled('div')`
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div {
    text-decoration: none;
    color: #000;
    padding-right: 3px;
  }

`;

const FooterCopyright = () => {
  return (
    <FooterCopyrightWrapper>
      <Query
        query={footerQuery}
        variables={{
          ids: ["df0c4773-02c2-4339-a3d9-7a5beb7f468e"],
        }}
      >
        {({ data, loading, error }) => {
          if (loading || error) {
            return null;
          }
          const sections = data.content?.content?.[0]?.items;

          return (
            <>
            {sections.map((section, index) => (
              section.type === 'FooterCopyright' && (
                <Rights>
                  <div data-detector="off">{section.properties[0].value.value}</div>
                </Rights>
              )
            ))}          
          </>
        );
        }}
      </Query>      
  </FooterCopyrightWrapper>
  );
}

export default FooterCopyright;
