import React from 'react';
import t from '@jetshop/intl';
import { styled } from "linaria/react";
import { ReactComponent as Search } from '../../../svg/Search.svg';
import posed from 'react-pose';
import { MenuButton, IconContainer } from './Header';
import { Above } from '@jetshop/ui/Breakpoints';

import { theme } from "../../Theme";

const Button = styled(
  posed('button')({
    enter: {
      marginTop: 0,
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-30px',
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)`
  margin-left: 1rem;
  margin-right: 1rem;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
  :hover {
    color: ${theme.colors.black};
    svg {
      use {
        fill: ${theme.colors.black};
      }
    }
  }
  :focus {
    outline: none;
  }
  svg {
    height: 20px;
    width: 20px;
    use {
      fill: #878787;
    }
  }
`;

function SearchButton(props) {
  const { setSearchOpen, searchIsOpen, ...rest } = props;
  return (
    <Above breakpoint="md">
      {matches =>
        matches ? (
          <Button onClick={() => setSearchOpen(true)} {...rest}>
            <Search />
          </Button>
        ) : (
          <MenuButton onClick={() => setSearchOpen(true)}>
            <IconContainer>
              <Search />
            </IconContainer>
            <label>{t('Search')}</label>
          </MenuButton>
        )
      }
    </Above>
  );
}

export default SearchButton;
