import t from '@jetshop/intl';
import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import FooterLinkContent from './FooterLinkContent';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import FooterQuery from '../Footer/FooterQuery.gql';
import { KlaviyoEmbeded } from '../../Klaviyo/KlaviyoEmbeded';
import FooterBottomArea from './FooterBottomArea';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import ChannelSelector from '../Header/ChannelSelector/ChannelSelector';

import { theme } from "../../Theme";

const NewsletterandSocialLinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: flex-start;
  justify-content: initial;
`;

const Wrapper = styled('section')`
  text-align: left;
  font-size: 16px;
  > h2 {
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  background-color: ${theme.colors.lightBeige};
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px 5px 40px;

  ${theme.below.md} {
    flex-direction: column;
    padding: 0 0 10px 0;
  }

}
`;

const NewsletterArea = styled('div')`
  display: block;
  width: 280px;
  color: black;
  padding: 22px 35px 16px 10px;
  //Klaviyo Stuf
  div {  
    font-family: "futura-pt",sans-serif !important;
    span {
      font-family: "futura-pt",sans-serif !important;
      font-weight: 400 !important;
    }
    input, button {
      font-family: "futura-pt",sans-serif !important;
    }
    button {
      width: 40px;
    }
    label {
      svg {
        margin-right: 4px !important;
        margin-top: 4px;
        circle {
          cx: 8;
          cy: 8;
          r: 7.5;
        }
      }
    }
  }

  ${theme.below.md} {
    padding-right: 20px;
    padding-left: 20px;
    width: auto;
    form {
      max-width: 1000px !important;
    }
  }
`;

const CountrySelectorAndSocialLinks = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 18px 20px;
`;

const FooterCategoryLink = ({ id }) => {
  const { data, loading, error } = useQuery(FooterQuery, {
    variables: {
      id: id
    }
  });
  if (error) return null;
  if (loading) return null;

  return (
    <Link to={data.category.primaryRoute?.path}>{data.category.name}</Link>
  );
};

export const FooterLinks_ = ({ closeMenu = null }) => {
  return (
    <div onClick={closeMenu}>
      <FooterCategoryLink id={308} />
      <FooterCategoryLink id={304} />
      <FooterCategoryLink id={309} />
      <Link to="/stores">{t('Stores')}</Link>
    </div>
  );
};

const Footer = ( ) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <Wrapper>
      <Above breakpoint="md">
      {matches =>
        matches && (
          <>
            <WrapFooterNav>
              <FooterLinkContent />
              <NewsletterandSocialLinksWrapper>
                <NewsletterArea>
                  <KlaviyoEmbeded categoryId={524} />
                </NewsletterArea>
                <SocialLinks />
              </NewsletterandSocialLinksWrapper>
            </WrapFooterNav>
            <FooterBottomArea />
          </>
        )}
      </Above>
      <Below breakpoint="md">
        {matches =>
          matches && (
            <>
            <WrapFooterNav>
              <NewsletterArea>
                <KlaviyoEmbeded categoryId={524} />
              </NewsletterArea>
              <FooterLinkContent />
              <CountrySelectorAndSocialLinks>
                <ChannelSelector setMenuOpen={setMenuOpen} />
                <SocialLinks />
              </CountrySelectorAndSocialLinks>
            </WrapFooterNav>
            <FooterBottomArea />
          </>
          )
        }
      </Below>
    </Wrapper>
  );
};

export default Footer;
