import { ReactComponent as Search } from '../../../svg/Search.svg';
import * as React from 'react';
import { styled } from "linaria/react";
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import { theme } from "../../Theme";
const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const SearchFieldWrapper = styled('div')`
  width: 12rem;
  position: relative;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15em;
  font-weight: 400;
  display: flex;
  height: 3rem;
  width: 100%;
  overflow: hidden;

  input {
    border: 0;
    background: #f3f3f3;
    height: 100%;
    padding: 0 65px 0 20px;
    width: 100%;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.15em;
    border-radius: 0;
    appearance: none;

    &:focus {
      outline: none;
    }

    &[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  svg {
    position: absolute;
    height: 45px;
    width: 50px;
    padding: 10px;
    right: 0px;
    top: -1px;
    circle,
    line {
      stroke: ${theme.colors.lightgrey};
    }
  }

  ${theme.below.lg} {
    width: 100%;
    height: 100%;
  }
`;

const SearchField = _a => {
  var {
      initialValue,
      className,
      placeholder = 'Sök',
      autocompleteQuery,
      hideTarget,
      setMenuOpen
    } = _a,
    rest = __rest(_a, [
      'initialValue',
      'className',
      'placeholder',
      'autocompleteQuery'
    ]);

  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );

  return (
    <SearchAutoCompleteContainer
      {...Object.assign(
        {
          autocompleteQuery: autocompleteQuery,
          initialValue: initialValue,
          onSubmit: () => setMenuOpen(false)
        },
        rest
      )}
    >
      {searchField => (
        <Wrapper className={className}>
          <SearchFieldWrapper>
            <input
              {...Object.assign(
                {
                  placeholder: placeholder
                },
                searchField.getInputProps()
              )}
              onSubmit={() => setMenuOpen(false)}
            />
            <Search
              onClick={() => {
                setMenuOpen(false);
                searchField.triggerSearch();
              }}
            />
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Wrapper = SearchFieldWrapper;
export default SearchField;

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(p[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]];
      }
    return t;
  };
