import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import React, { Fragment, useContext } from 'react';
import { styled } from "linaria/react";
import { theme } from "../Theme";
import {
  ProductSection as UIProductSection,
  SectionHeading
} from './ProductInfo';
import GradingSection from './GradingSection';
import { ReactComponent as AngleDown } from '../../svg/Angle-down.svg';
import { ReactComponent as AngleUp } from '../../svg/Angle-up.svg';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import LipscoreWidget from '../Lipscore/LipscoreWidget';
import { Helmet } from 'react-helmet-async'

const Description = styled('article')`
  line-height: 25px;
  padding-bottom: 1.5rem;
`;

const AccordionWrapper = styled('div')`
  margin: 0 20px;
`;

const ProdInfoHeading = styled('div')`
  margin-bottom: 20px;
  ${theme.above.md} {
    display: none;
  }
`;

export const ProductSection = styled(UIProductSection)`
  border-top: 1px solid #e8e8e8;
  padding: 8px 10px !important;
  margin: 0 auto;
  font-size: 0.875rem;
  max-width: 866px;
  width: 100%;
  &:last-of-type {
    border-bottom: 1px solid #e8e8e8;
  }

  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .accordion-content {
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        list-style-position: outside;
      }
    }
    padding-bottom: 1.5rem;
  }
  ${SectionHeading} {
    display: flex;
    justify-items: space-between;
    align-items: center;
    margin-bottom: 0;
    font-weight: 300;
    letter-spacing: 0.03em;
    font-size: 16px;
    padding-bottom: 0;

    + * > :first-child {
      margin-top: 1rem;
    }
  }

  a {
    color: #333;
  }

  .grading-wrapper {
    padding-top: 0.5rem;
  }
`;

export const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
  display: flex;
`;

const MaterialIconsWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0;
  flex-wrap: wrap;

  img {
    height: 80px;
    margin: 10px 30px 10px 0;
  }
`;

const LipscoreContainer = styled('div')`

`;

const LipscoreMeta = () => {
  return (
    <Helmet>
      <link rel="stylesheet" type="text/css" href="/lipscore-style.css" />
    </Helmet>
  );
};

export const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <AngleUp /> : <AngleDown />}</IndicatorIcon>
);

const ProductInfoAccordion = ({
  product,
  fit,
  material,
  details,
  productInfo,
  activeIndex,
  setActiveTab,
  biking,
  breathability,
  casualWear,
  durability,
  freerideSkiing,
  insulation,
  lightweight,
  mountaineering,
  outdoorAndHiking,
  pisteSkiing,
  skiTouring,
  // fitMeasure,
  training,
  waterproofness,
  windproofness,
  // sizeGuide,
  materialIcons,
  selectedVariation
}) => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <AccordionWrapper>
    <Accordion>
      {({ openIndexes, handleClick, AccordionContent }) => (
        <Fragment>
          {productInfo &&
          productInfo.htmlValue &&
          productInfo.htmlValue.length > 0 ? (
            <ProductSection>
              <SectionHeading onClick={() => handleClick(0)}>
                <span>{t('Product description')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(0)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(0)}>
                <ProdInfoHeading className='short-description'>
                  {product.shortDescription}
                </ProdInfoHeading>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: productInfo.htmlValue
                  }}
                />
              </AccordionContent>
            </ProductSection>
          ) : (
            <ProductSection>
              <SectionHeading onClick={() => handleClick(0)}>
                <span>{t('Product description')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(0)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(0)}>
                <ProdInfoHeading className='short-description'>
                  {product.shortDescription}
                </ProdInfoHeading>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
                />
              </AccordionContent>
            </ProductSection>
          )}
          <ProductSection>
            <SectionHeading onClick={() => handleClick(1)}>
              <span>{t('Performance')}</span>
              <AccordionIndicator isOpen={openIndexes.includes(1)} />
            </SectionHeading>
            <AccordionContent isOpen={openIndexes.includes(1)}>
              <GradingSection
                biking={biking}
                breathability={breathability}
                casualWear={casualWear}
                durability={durability}
                freerideSkiing={freerideSkiing}
                insulation={insulation}
                lightweight={lightweight}
                mountaineering={mountaineering}
                outdoorAndHiking={outdoorAndHiking}
                pisteSkiing={pisteSkiing}
                skiTouring={skiTouring}
                training={training}
                waterproofness={waterproofness}
                windproofness={windproofness}
              />
            </AccordionContent>
          </ProductSection>
          <ProductSection>
            <SectionHeading onClick={() => handleClick(3)}>
              <span>{t('Details')}</span>
              <AccordionIndicator isOpen={openIndexes.includes(3)} />
            </SectionHeading>
            <AccordionContent isOpen={openIndexes.includes(3)}>
              {details &&
              details.listValues &&
              details.listValues.length > 0 ? (
                <div index="4" label={t('Details')}>
                  <div className="accordion-content">
                    <ul>
                      {details.listValues.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
            </AccordionContent>
          </ProductSection>
          {material && material.listValues && material.listValues.length > 0 && (
            <ProductSection>
              <SectionHeading onClick={() => handleClick(4)}>
                <span>{t('Material')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(4)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(4)}>
                <div index="3" label={t('Material')}>
                  <div className="accordion-content">
                    <ul>
                      {material.listValues.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                    {materialIcons && materialIcons.htmlValue.length > 0 && (
                      <MaterialIconsWrapper
                        dangerouslySetInnerHTML={{
                          __html: materialIcons.htmlValue
                        }}
                      />
                    )}
                  </div>
                </div>
              </AccordionContent>
            </ProductSection>
          )}

          <ProductSection>
            <SectionHeading onClick={() => handleClick(5)}>
              <span>{t('Product Review')}</span>
              <AccordionIndicator isOpen={openIndexes.includes(5)} />
            </SectionHeading>
            <AccordionContent isOpen={openIndexes.includes(5)}>
              {/* check if we have reviews */}
              {true ? (
                <div index="5" label={t('Product Review')}>
                  <div className="accordion-content">
                    <LipscoreContainer>
                      <LipscoreMeta />
                      <LipscoreWidget product={product} type="review-list" />
                    </LipscoreContainer>
                  </div>
                </div>
              ) : null}
            </AccordionContent>
          </ProductSection>
        </Fragment>
      )}
    </Accordion>
    </AccordionWrapper>
  );
};

export default ProductInfoAccordion;
