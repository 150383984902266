import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Above } from '@jetshop/ui/Breakpoints';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import Selector from './Selector';
import { styled } from "linaria/react";
import { ReactComponent as Arrow } from '../../../../svg/Arrow.svg';

import { theme } from "../../../Theme";

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 12;
  top: 39px;
  right: 0;

  ${theme.below.md} {
    position: fixed;
    right: auto;
    left: 10px;
    top: 0;
    z-index: 100;
    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const CountryFlag = styled('img')`
  height: 20px;
  margin-right: 5px;
  margin-left: 1rem;
  ${theme.below.md} {
    margin-left: 0;
    height: 25px;
  }
`;

const ArrowWrapper = styled('div')`
  svg {
    width: 10px;
    height: 11px;
    path {
      stroke: #787878;
    }

    ${theme.below.md} {
      margin-left: 10px;
    }
  }
`;

const MobileSelWrapper = styled('div')`
  display: flex;
  align-items: center;
`;


export const ChannelSelectorFlyout = () => {
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <Fragment>
          <FlyoutTarget id="channel-selector">
            {({ isOpen, hideTarget }) => (
              <Flyout>
                <Selector
                  channels={channels}
                  hideTarget={hideTarget}
                  selectedChannel={selectedChannel}
                  updateChannel={updateChannel}
                />
              </Flyout>
            )}
          </FlyoutTarget>
        </Fragment>
      )}
    </ChannelContext.Consumer>
  );
};

const HeaderChannelSelector = ({ setMenuOpen }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <Fragment>
      <FlyoutTrigger id="channel-selector">
        {({ showTarget, hideTarget, isOpen }) => (
          <button
            onClick={() => {
              isOpen ? hideTarget() : showTarget();
              setMenuOpen(isOpen ? hideTarget() : showTarget());
            }}
            style={{ background: 'transparent' }}
          >
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <>
                    <Flag />
                    <Arrow />
                  </>
                ) : (
                  <MobileSelWrapper>
                    <Flag />
                    <span>{selectedChannel.country.name}</span>
                    <ArrowWrapper>
                      <Arrow />
                    </ArrowWrapper>
                  </MobileSelWrapper>
                )
              }
            </Above>
          </button>
        )}
      </FlyoutTrigger>
    </Fragment>
  );
};

const Flag = () => {
  const [countryCode, setCountryCode] = useState('');
  const { selectedChannel } = useContext(ChannelContext);

  useEffect(() => {
    setCountryCode(selectedChannel?.country?.code);
  }, [selectedChannel?.country]);

  return (
    <CountryFlag
      src={`https://countryflags.jetshop.io/${countryCode}/flat/32.png`}
      alt={selectedChannel?.name}
    />
  );
};

export default HeaderChannelSelector;
