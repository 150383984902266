import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from "linaria/react";
import { css, cx } from "linaria";
import { Price } from '../Price';
import { theme } from '../Theme';

const wrapperStyling = css`
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  .product-card-detail {
    background: white;
    padding: 0.75em;
    line-height: 1.35;

    h3 {
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.03em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > div {
      display: flex;
      justify-content: center;
      padding-top: 5px;
      div {
        padding: 0 10px;
      }
    }
  }

  .select-variant {
    z-index: 3;
  }
`;

const productTagStyling = css`
  //product image tag styling
  [data-flight-image-children] {
    position: absolute !important;

    .sustainable {
      color: ${theme.colors.white};
      background: ${theme.colors.black};
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      font-weight: 400;
      padding: 3px 10px;
      span:first-letter {
        text-transform: uppercase;
      }
      ${theme.below.sm} {
        font-size: 10px;
        line-height: 15px;
        height: 17px;
      }
    }

    .oversized,
    .superslim,
    .webexclusive {
      color: ${theme.colors.white};
      background: ${theme.colors.black};
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      font-weight: 400;
      padding: 3px 10px;
      span:first-letter {
        text-transform: uppercase;
      }
      ${theme.below.sm} {
        font-size: 10px;
        line-height: 15px;
        height: 17px;
      }
    }

    .badge-bottom-left {
      position: absolute;
      bottom: 3px;
      left: 8px;
      right: 8px;
      display: flex;
      flex-wrap: wrap;
      & > div {
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
`;

const BadgeWrapper = styled('div')`
  font-size: 1rem;
`;

export function ProductCard({
  product,
  className,
  imageAspect = '13:17',
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;

  return (
    <Tag
      className={cx('product-card', wrapperStyling, productTagStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
      style={{ margin: "0.2em" }}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{ margin: "0" }}

      >
        {hasImages ? (
          <Image
            cover
            sizes={[1/2, 1/2, 1/2, 1/3, 1/3, 1/3, 500]}
            aspect={imageAspect}
            alt={product.images[0].alt}
            src={product.images[0].url}
            modifiedDate={product.images[0].modifiedDate}
          >
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
          </Image>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
          </header>
          <Price price={product.price} previousPrice={product.previousPrice} />
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
