import useHeaderHeight from '../../../hooks/useHeaderHeight';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from "linaria/react";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AngleDown } from '../../../svg/Angle-down.svg';
import { theme } from '../../Theme';
import { StaticMenuContent } from './StaticMenuContent';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { SectionHeading } from '../../ProductPage/ProductInfo';
import { IndicatorIcon } from '../../ProductPage/ProductInfoAccordion';
import { cx } from "linaria";

const AngleRight = styled(AngleDown)`
  transform: rotate(-90deg);
`;

const Scroll = styled('div')`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  /* top: ${props => props.topPosition}px; */
  /* height: calc(100vh - ${props => props.topPosition}px); */
  z-index: 99;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
    max-height: 85vh;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;

const MenuWrapper = styled('div')`
  background: white;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  padding: 5px 20px 0 20px;

  &.accordion-open {
    height: auto !important;
  }

  a,
  button {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    color: black;
  }

  section > div {
    .level-three {
      padding-left: 30px;
    }
  }

  .categories > section {
    &.open {
      border-bottom: 1px solid ${theme.colors.creme};
    }
  }
`;

const AccordionSection = styled('section')`
  display: flex;
  flex-direction: column;
  width: 100%;

  h2,
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid ${theme.colors.creme};
    font-size: 20px;
    /* padding-bottom: 0.25em; */
  }
`;

const AccordionContentContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  .lower-level {
    margin-left: 2rem;
  }
`;

const MobileMenuSectionHeading = styled('h2')`
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #EAE5DC;
  a {
    font-size: 20px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const MenuAccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <AngleDown /> : <AngleRight />}</IndicatorIcon>
);

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const [anyAccordionOpen, setAnyAccordionOpen] = useState(false);

  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen, activeId]);

  return <>
    <MenuWrapper
      active={active}
      className={anyAccordionOpen ? 'accordion-open' : null}
    >
      <div className="categories">
        <Accordion single>
          {({ openIndexes, handleClick, AccordionContent }) => (
            <>
              {categories.map((cat, index) =>
                cat?.hasSubcategories && cat?.subcategories ? (
                  <AccordionSection
                    key={'header-menu-accordion-section' + index}
                    className={cx(openIndexes.includes(index) ? 'open' : null)}
                  >
                    <SectionHeading
                      className={cx(
                        'title',
                        openIndexes.includes(index) ? 'open' : null
                      )}
                      onClick={() => {
                        handleClick(index);
                        setAnyAccordionOpen(!openIndexes.includes(index));
                      }}
                    >
                      {cat.name}
                      <MenuAccordionIndicator
                        // key={Math.random()}
                        isOpen={openIndexes.includes(index)}
                      />
                    </SectionHeading>
                    <AccordionContent 
                      key={'menu-content-' + index + cat?.id}
                      isOpen={openIndexes.includes(index)}>
                      {cat?.subcategories?.map((lvl2, index) => {
                        if (lvl2?.name === 'Menybild') return null;
                        return (
                          <AccordionContentContainer key={index + lvl2?.name}>
                            <Link
                              onClick={rest.close}
                              to={lvl2.primaryRoute.path}
                              key={`link-${lvl2.id}`}
                              className={'level-two'}
                            >
                              <span>{lvl2.name}</span>
                            </Link>
                            {lvl2?.hasSubcategories &&
                              lvl2?.subcategories?.length > 0 &&
                              lvl2?.subcategories?.map(lvl3 => {
                                return (
                                  <Link
                                    onClick={rest.close}
                                    to={lvl3.primaryRoute.path}
                                    key={`link-${lvl3.id}`}
                                    className={'level-three'}
                                  >
                                    <span>{lvl3.name}</span>
                                  </Link>
                                );
                              })}
                          </AccordionContentContainer>
                        );
                      })}
                    </AccordionContent>
                  </AccordionSection>
                ) : (
                  <MobileMenuSectionHeading className={'title'} key={'mobile-section-heading-' + index}>
                    <Link
                      onClick={rest.close}
                      to={cat.primaryRoute.path}
                      key={Math.random()}
                      style={{ paddingTop: '0.55em' }}
                      className={cat.name === 'På salg' ? 'red' : ''}
                    >
                      {cat.name}
                      <MenuAccordionIndicator isOpen={false} />
                    </Link>
                  </MobileMenuSectionHeading>
                )
              )}
            </>
          )}
        </Accordion>
      </div>
    </MenuWrapper>
  </>;
};

export const PositionedDrawer = ({
  isOpen,
  size = 320,
  children,
  left,
  belowHeader
}) => {
  const topPosition = useHeaderHeight();
  return (
    <NewDrawer
      style={{ top: '60px' }}
      topPosition={belowHeader ? topPosition : 0}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({
  data,
  size = 400,
  left = false,
  belowHeader
}) {
  if (!(data && data.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <PositionedDrawer
          isOpen={drawer.isOpen}
          size={size}
          left={left}
          belowHeader={belowHeader}
        >
          <Scroll>
            <MenuLevel
              isOpen={drawer.isOpen}
              close={drawer.hideTarget}
              categories={data.categories}
              active={true}
            />
            <StaticMenuContent />
          </Scroll>
        </PositionedDrawer>
      )}
    </DrawerTarget>
  );
}
