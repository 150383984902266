import t from '@jetshop/intl';
import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import FooterLinkContent from './FooterLinkContent';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import FooterQuery from '../Footer/FooterQuery.gql';
import ChannelSelector from '../Header/ChannelSelector/ChannelSelector';

import { theme } from "../../Theme";

const Wrapper = styled('section')`
  text-align: left;
  font-size: 16px;
  padding-top: 32px;
  > h2 {
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  background-color: ${theme.colors.lightBeige};
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}
`;

const CountrySelectorAndSocialLinks = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;

const FooterCategoryLink = ({ id }) => {
  const { data, loading, error } = useQuery(FooterQuery, {
    variables: {
      id: id
    }
  });
  if (error) return null;
  if (loading) return null;

  return (
    <Link to={data.category.primaryRoute?.path}>{data.category.name}</Link>
  );
};

export const StaticMobileMenuFooterLinks = ({ closeMenu = null }) => {
  return (
    <div onClick={closeMenu}>
      <FooterCategoryLink id={308} />
      <FooterCategoryLink id={304} />
      <FooterCategoryLink id={309} />
      <Link to="/stores">{t('Stores')}</Link>
    </div>
  );
};

const StaticMobileMenuFooter = ({ closeMenu = null }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <Wrapper>
      <WrapFooterNav>
        <FooterLinkContent isStaticMobileMenu={true} />
        <CountrySelectorAndSocialLinks>
          <ChannelSelector setMenuOpen={setMenuOpen} />
          <SocialLinks />
        </CountrySelectorAndSocialLinks>
      </WrapFooterNav>
    </Wrapper>
  );
};

export default StaticMobileMenuFooter;
