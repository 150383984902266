import SSPEot from './source-sans-pro-v11-latin-regular.eot';
import SSPWoff from './source-sans-pro-v11-latin-regular.woff';
import SSPWoff2 from './source-sans-pro-v11-latin-regular.woff2';
import SSPTtf from './source-sans-pro-v11-latin-regular.ttf';

import SSPEotBold from './source-sans-pro-v11-latin-700.eot';
import SSPWoffBold from './source-sans-pro-v11-latin-700.woff';
import SSPWoff2Bold from './source-sans-pro-v11-latin-700.woff2';
import SSPTtfBold from './source-sans-pro-v11-latin-700.ttf';

import SSPEotSemiBold from './source-sans-pro-v13-latin-600.eot';
import SSPTtfSemiBold from './source-sans-pro-v13-latin-600.ttf';
import SSPWoffSemiBold from './source-sans-pro-v13-latin-600.woff';
import SSPWoff2SemiBold from './source-sans-pro-v13-latin-600.woff2';

import { css } from "linaria";

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin


export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'Source Sans Pro';
        src: url(${SSPEot}); /* IE9 Compat Mode */
        src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
            url(${SSPWoff2}) format('woff2'),
            url(${SSPWoff}) format('woff'),
            url(${SSPTtf}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    
      /* Bold font */
      @font-face {
        font-family: 'Source Sans Pro';
        src: url(${SSPEotBold}); /* IE9 Compat Mode */
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
            url(${SSPWoff2Bold}) format('woff2'),
            url(${SSPWoffBold}) format('woff'),
            url(${SSPTtfBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: url(${SSPEotSemiBold}); /* IE9 Compat Mode */
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
            url(${SSPWoff2SemiBold}) format('woff2'),
            url(${SSPWoffSemiBold}) format('woff'),
            url(${SSPTtfSemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
       }
  `;
}
