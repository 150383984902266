import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from "linaria/react";
import { Price } from '../Price';
import {theme}  from '../Theme';


const common = `
  color: ${theme.colors.mediumgrey};
  margin-bottom: 1em;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.03em;
`;

const UntilLimit = styled('div')`
  ${common};
  [data-flight-price] {
    display: inline;
    > * {
      display: inline;
    }
  }
`;

const Reached = styled('div')`
  ${common};

  h2 {
    font-weight: 400;
  }
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit)
    return (
      <UntilLimit key="until-limit">
        {t.rich(`Spend {price} more to qualify for free shipping!`, {
          price: <Price key="price" price={untilLimit} />
        })}
      </UntilLimit>
    );

  return (
    <Reached {...rest}>
      <h2>{t('Delivery')}</h2>
      <label>{t('Free for your order')}</label>
    </Reached>
  );
}

export default FreeShipping;
