import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from "linaria/react";

import { theme } from "../Theme";

const TabHeader = styled('li')`
  display: inline-block;
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  border-bottom: 5px solid transparent;
  position: relative;
  font-weight: normal;
  margin-right: 30px;
  letter-spacing: 0.03em;

  &.tab-list-active {
    font-weight: 400;
    color: ${theme.colors.primary};
    border-bottom: 2px solid #000;
  }
`;

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  };

  render() {
    const {
      onClick,
      props: { activeTab, index, label }
    } = this;

    let className = 'tab-list-item';

    if (activeTab === index) {
      className += ' tab-list-active';
    }

    return (
      <TabHeader className={className} onClick={onClick}>
        {label}
      </TabHeader>
    );
  }
}

export default Tab;
