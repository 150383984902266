import React from 'react';
import { styled } from "linaria/react";
import CategoryLink from '@jetshop/ui/CategoryLink';

import { theme } from "../../../Theme";

const Wrapper = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0px;
  padding-bottom: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #efefef;

  ul {
    display: flex;
    flex-direction: column;
    max-height: 8rem;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin-bottom: 1rem;
  }

  li {
    color: ${theme.colors.darkgrey};
    flex: auto;
    max-width: 10rem;
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    margin: 3px 10px 3px 0px;
    word-break: initial;
    a {
      opacity: 1;
      color: ${theme.colors.darkgrey};
      display: block;
      word-break: initial;
      :hover,
      &.active {
        color: ${theme.colors.black};
      }
    }
  }

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    opacity: 0.8;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const Heading = styled('h2')`
  text-align: left;
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading.name}
        </CategoryLink>
      </Heading>
      <ul>
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
