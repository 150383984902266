import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { theme } from "../../Theme";
import React from 'react';
import { styled } from "linaria/react";
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import cartQuery from '../../Cart/CartQuery.gql';
import { Above } from '@jetshop/ui/Breakpoints';
import SmallProductToast from './SmallProductToast';

const Container = styled('aside')`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
`;

const CheckoutButton = styled.a`
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: ${theme.space[2]};
  margin: ${theme.space[1]};
  text-align: center;
  background: ${theme.colors.blue};
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;


const ProductImageWrapper = styled('div')`
  width: 100%;
  margin-right: 0;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    background: #000000;
    color: #fff;
    font-weight: 400;

    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  padding-top: 32px;

  position: relative;
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  width: 100%;
  text-align: center;

  h3 {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    padding-top: 5px;
  }
`;

const CloseButton = styled('button')`
  outline: none;
  top: 2px;
  right: 10px;
  position: absolute;
  background: transparent;
  svg {
    width: 1em;
    height: 1em;
    use {
      fill: #000000;
    }
  }
`;

const Header = styled('h3')`
  font-size: 14px;
  margin-bottom: ${theme.space[1]};
`;

const ProductName = styled('p')`
  font-size: 14px;
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: 14px;
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  price,
  error
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const items = cart?.items;

  const klaviyoTrack = event => {
    if (typeof window !== undefined && window?._learnq && cart) {
      const KLAV = window._learnq;
      const data = {
        $event_id: `${cart.id}_${Math.floor(Date.now() / 1000)}`,
        $value: cart?.productTotal?.incVat,
        ItemNames: items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Categories: [
          ...new Set(
            items?.flatMap(item =>
              item?.product?.categories?.flatMap(category => category?.name)
            )
          )
        ],
        Items: items?.flatMap(item => ({
          ProductID: item?.product?.id,
          SKU: item?.product?.articleNumber,
          ProductName: item?.product?.name,
          Quantity: item?.quantity,
          ProductCategories: [
            ...new Set(
              item?.product?.categories?.flatMap(category => category?.name)
            )
          ],
          ItemPrice: item?.product?.price?.incVat,
          RowTotal: item?.total?.incVat,
          ProductURL:
            window.location.origin + item?.product?.primaryRoute?.path,
          ImageURL:
            item?.product?.images?.[0]?.url +
            `?timestamp=${item?.product?.images?.[0]?.modifiedDate}`
        }))
      };
      KLAV?.push(['track', 'Started Checkout', data]);
    }
  };

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                sizes={'800'}
                aspect={'13:17'}
                alt={image.alt}
                modifiedDate={image?.modifiedDate}
                cover
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('Added {quantity} items to bag', { quantity })}
              </Header>
            )}
            <ProductName>{product.name}</ProductName>
            {!error && (
              <Price price={price.price} previousPrice={price.previousPrice} />
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
          <CloseButton onClick={closeToast}>
            <Cross />
          </CloseButton>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={e => {
                  klaviyoTrack(e);
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => (
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <ProductToast {...props} cart={data && data.cart} />
          ) : (
            <SmallProductToast {...props} cart={data && data.cart} />
          )
        }
      </Above>
    )}
  </CartProvider>
);

export default ProductToastWrapper;
