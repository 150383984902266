import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from "linaria/react";
import { cx } from "linaria";
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import ChannelSelector, {
  ChannelSelectorFlyout
} from './ChannelSelector/ChannelSelector';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { SearchBar } from './SearchBar';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import SearchButton from './SearchButton';
import FlyoutMenu from './FlyoutMenu';
import useHeaderHeight from '../../../hooks/useHeaderHeight';
import { useScrollPosition } from '../../../hooks/useScrollPosition';
import { ReactComponent as Search } from '../../../svg/Search.svg';

import { theme } from "../../Theme";

const Container = styled('header')`
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid ${theme.colors.creme};
  ${theme.below.lg} {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    transition: all 0.3s ease-in-out;
    height: 60px;
    max-height: 60px;
    &.open {
      height: 115px;
      max-height: 115px;
    }
  }

  a {
    color: black;
  }
`;

export const MenuButton = styled('button')`
  border: 0;
  appearance: none;
  background: none;
  &:focus {
    outline: none;
  }
  svg {
    width: 22px !important;
    height: 25px !important;
    display: block;
    ${theme.below.lg} {
      stroke-width: 1px;
      width: 28px !important;
      height: 28px !important;
    }
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const HeaderWrapper = styled('div')`
  ${theme.below.lg} {
    padding: 0 1rem;

    background: white;

    .search-bar {
      padding: 0 0 0 0;
      background: white;
      border: none;
      height: 38px;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      opacity: 0;

      > div {
        border: none !important;

        input {
          background: white;
          border: none;
          border-radius: 18px;
          letter-spacing: 0em;
        }

        svg {
          height: 20px;
          width: 45px;
          * {
            stroke-width: 1px;
          }
          padding: 0;
          right: 0px;
          top: -1px;
        }
      }
    }

    &.open {
      .search-bar {
        max-height: 38px;
        margin: 10px 0 !important;
        opacity: 1;
        input {
          border: 1px solid #b9b9b9;
        }
      }
    }
  }
`;
const HeaderInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  ${theme.below.lg} {
    padding: 13px 0px 13px 0px;
  }
`;

const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  & > div:first-of-type {
  }
  &.left {
    flex: 2;
    justify-content: flex-start;
    text-align: left;

    .search-icon {
      height: 20px;
      width: auto;
      margin-top: 2px;
      margin-left: 1rem;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      visibility: hidden;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.center {
    justify-content: center;
    flex: 1;
    text-align: center;
    ${theme.below.lg} {
      flex: 2;
      margin-bottom: -12px;
    }

    a {
      margin-top: 6px;
      width: 100%;
      height: 100%;
    }
  }
  &.right {
    flex: 2;
    justify-content: flex-end;
    text-align: right;
    ${theme.below.lg} {
      svg {
        stroke-width: 1px;
        width: 28px !important;
        height: 28px !important;
      }
    }
  }

  #menu-button {
    background: transparent;
  }
`;
const LogoWrapper = styled('div')`
  svg {
    width: 90px;
    ${theme.below.md} {
      width: 78px;
    }
  }
`;

export default function Header() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  const [searchOpen, setSearchOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const topPosition = useHeaderHeight();

  // For mobile search in header
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [prevScrollValue, setPrevScrollValue] = useState(0);
  const scrollValue = useScrollPosition();

  useEffect(() => {
    //Dirty fix to make searchbar toggle with animation without get stuck
    if (Math.abs(prevScrollValue - scrollValue) > 49) {

      if (searchOpen && scrollValue < 1) {
        setShowSearchInput(true);
      }

      if (scrollValue > 5) {
        setShowSearchInput(false);
      }
    }
    
    setPrevScrollValue(scrollValue);
  }, [scrollValue]);

  return <>
    <ChannelBanner />
    <Container className={showSearchInput ? 'open' : null}>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <CategoryMenu data={result.data} />
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <LogoWrapper>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    <SearchButton
                      searchOpen={searchOpen}
                      setSearchOpen={setSearchOpen}
                    />
                    <FavouriteCount />
                    <CartButton />
                    <ChannelSelector setMenuOpen={setMenuOpen} />
                  </HeaderColumn>
                </HeaderInner>
                {searchOpen && (
                  <SearchBar
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                  />
                )}
              </HeaderWrapper>
            </>
          ) : (
            <>
              <HeaderWrapper
                className={showSearchInput ? 'open' : null}
                id="site-header"
              >
                <HeaderInner>
                  <HeaderColumn className="left">
                    <DrawerTrigger
                      preventOverflow={true}
                      id="menu-drawer"
                      coverStyles={{ top: topPosition }}
                    >
                      {drawer => (
                        <button
                          data-testid="menu-button"
                          id="menu-button"
                          onClick={
                            drawer.isOpen
                              ? drawer.hideTarget
                              : drawer.showTarget
                          }
                        >
                          <Menu />
                        </button>
                      )}
                    </DrawerTrigger>

                    <Search
                      className={cx(
                        'search-icon show'
                      )}
                      onClick={() => {
                        setShowSearchInput(!showSearchInput);
                      }}
                    />
                  </HeaderColumn>
                  <HeaderColumn className="center">
                    <LogoWrapper>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>
                  <HeaderColumn className="right">
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
                <SearchBar
                  className={'searchbar-mobile'}
                  searchOpen={true}
                  setSearchOpen={() => {}}
                  setMenuOpen={setMenuOpen}
                />
              </HeaderWrapper>
            </>
          )
        }
      </Above>
    </Container>
    <ChannelSelectorFlyout />
    <CartFlyout />
    <FlyoutMenu left={true} data={result.data} belowHeader={true} />
  </>;
}
