import CategoryLink from '@jetshop/ui/CategoryLink';
import Image from '@jetshop/ui/Image';
import React from 'react';
import { styled } from "linaria/react";
import posed from 'react-pose';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import MenuImageQuery from './MenuImageQuery.gql';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { theme } from "../../../Theme";

const borderColor = '#e8e8e8';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  display: flex;
  flex-direction: row;
  padding: 0 1rem !important;
  margin: auto;
  width: 100%;
  border-left: 1px solid #efefef;
  min-height: 140px;
  > div:last-of-type {
    border-right: none;
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: 100%;
  margin: 0 auto;
  min-width: 50vw;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  border: 1px solid ${borderColor};
  border-width: 1px 0 1px 0;
  width: 100%;
  padding: 1rem 0;
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 100;
  height: 0;


  h2 {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase !important;
    color: #000;
  }
`;

const NoThirdTierItems = styled('div')`
  display: flex;
  flex: 1 1 auto;
  padding-bottom: 1rem;
  border-right: 1px solid #efefef;
  h2 {
    font-size: inherit;
    text-align: left;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    a {
      text-decoration: none;
      font-weight: 400;
      color: #000;
    }
  }
`;

const MenuImage = styled('div')`
  padding: 20px;
  display: flex;
  > div {
    display: flex;
    align-items: center;
    width: 200px;
    margin: 0 10px;
    > div {
      width: 100%;
      align-self: start;
    }
  }
`;

const LeftMenuColumn = styled('div')`
  padding: 10px;
  min-width: 200px;
`;

const RightMenuColumn = styled('div')`
  padding: 10px;
  min-width: 200px;
`;


const MenuImageContent = styled('div')`
  text-align: center;
  width: 100%;
  h3 {
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.08em;
    font-weight: 400;
    margin-bottom: 0;
    color: black;
    margin: 10px auto 5px auto;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
`;

export const MenuRightWrapper = styled('div')`
  text-align: left;
  padding: 0 20px 0 20px;
  flex: 1;
  border-left: 1px solid #efefef;
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-self: center;
    li {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.08em;
      text-align: left;
      margin: 0;
      margin-bottom: 8px;
      width: 100%;
      font-weight: 400;
      color: #000;

      a {
        text-decoration: none;
      }
    }
  }
`;

const Menybild = ({ catImage }) => (
  <MenuImageContent>
    <Link to={catImage.url}>
      <Image src={catImage.image} cover aspect={'680:450'} alt={catImage.image} sizes={'420'} />
      <h3>{catImage.label}</h3>
    </Link>
  </MenuImageContent>
);

const MenuImageItem = ({ closeNav, id }) => {
  return (
    <Query query={MenuImageQuery} variables={{ id: id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error!: ${error}`;

        return data?.category?.data?.items.length > 0 ? (
          data?.category?.data?.items[0]?.children?.map((item, index) => {

            const catImage = {
              image: item.properties[0]?.value?.value || '',
              url: item.properties[1]?.value?.value || '',
              label: item.properties[2]?.value?.value || ''
            }
            return (
              <div onClick={closeNav}
                key={index}>
                <Menybild 
                  key={index}
                  catImage={catImage} />
              </div>
            )
          })
        ) : null
      }}
    </Query>
  );
};

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper>
        <ContentFit>
          <MaxWidth>
            <LeftMenuColumn>
              {activeCategory &&
                activeCategory.subcategories.map((cat, index) =>
                  cat.subcategories.length === 0 ? (
                    <React.Fragment key={index}>
                      {cat.name !== 'Menybild' ? (
                        <NoThirdTierItems key={index}>
                          <h2>
                            <CategoryLink category={cat} onClick={closeNav}>
                              {cat.name}
                            </CategoryLink>
                          </h2>
                        </NoThirdTierItems>
                      ) : null}
                    </React.Fragment>
                  ) : ( null )
                )}
            </LeftMenuColumn>
            <RightMenuColumn>
              {activeCategory &&
                activeCategory.subcategories.map((cat, index) =>
                  cat.subcategories.length > 0 ? (
                    <SubMenuSection
                      key={cat.id}
                      heading={cat}
                      categories={cat.subcategories}
                      onSelect={closeNav}
                    />
                  ) : ( null )
                )}
            </RightMenuColumn>
          </MaxWidth>

          {activeCategory ? (
            <MenuImage key={activeCategory.id}>
              <MenuImageItem closeNav={closeNav} id={activeCategory.id} />
            </MenuImage>
          ) : null
          }
        </ContentFit>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
