import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import Badge from '../ui/Badge';
import { styled } from "linaria/react";

import { theme } from "../Theme";

const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  position:relative;

  svg {
    height: 22px;
    width: 22px;
    margin: 0;
    color: black;
    path {
      stroke-width: 0.7px;
    }
     ${theme.below.lg} {
     margin-bottom: -2px;
    }
  }
`;

export function FavouriteCount() {
  const { count } = useProductList();

  return (
    <Link to="/favourites" >
      <IconContainer>
        {count > 0 && <Badge text={count} />}
        <HeartSVG />
      </IconContainer>
    </Link>
  );
}

