import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from "linaria/react";

import ProductTab from './ProductTab';

const TabWrapper = styled('div')`
  width: 100%;
  display: block;
  margin: 0 auto 2rem auto;

  .tab-list {
    margin-top: -8px;
  }
`;
const TabList = styled('ol')`
  padding-bottom: 1rem;
`;
const TabContent = styled('div')`
  display: block;
  width: 100%;
  padding: 2rem 0;
  padding-top: 0.5rem;
  line-height: 1.4;
  font-size: 14px;

  .hide {
    display: none;
  }

  b,
  strong {
    font-weight: 400;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
  }

  ul {
    margin-bottom: 1rem;
    margin-left: 1rem;
    li {
      list-style-type: none;
      line-height: 1.6;
      position: relative;
      &:before {
        content: '·';
        font-size: 17px;
        vertical-align: middle;
        line-height: 20px;
        position: absolute;
        left: -14px;
        top: 0;
        bottom: 0;
      }
    }
  }
  div {
    line-height: 1.6;
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
  }
  u {
    display: block;
    cursor: pointer;
  }
`;

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.activeIndex
        ? this.props.activeIndex
        : this.props.children[0].props.index
    };
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab });
    this.props.setActiveTab(tab);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeIndex !== this.props.activeIndex &&
      this.state.activeTab !== this.props.activeIndex
    ) {
      this.setState({ activeTab: this.props.activeIndex });
    }
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this;

    let newChildren = [];
    children.map((child, index) => {
      if (child) {
        if (child.length) {
          child.map(childLvl2 => {
            newChildren.push(childLvl2);
            return newChildren;
          });
        } else {
          newChildren.push(child);
        }
      }
      return child;
    });

    return (
      <TabWrapper className="tabs">
        <TabList className="tab-list">
          {newChildren.map(child => {
            const { label, index } = child.props;

            if (child.props.children && child.props.children.length < 1) {
              return false;
            }
            return (
              <ProductTab
                activeTab={activeTab}
                index={index}
                key={index}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </TabList>
        <TabContent className="tab-content">
          {newChildren.map(child => {
            if (!child) {
              return false;
            }
            if (child[0]) {
              child = child[0];
            }
            if (child.props.index !== activeTab) return undefined;
            return child.props.children;
          })}
        </TabContent>
      </TabWrapper>
    );
  }
}

export default Tabs;
