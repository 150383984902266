// Removes tags and brackets and returns a string with maxLength ending with "..."
const decoderPreviewText = (content, maxLength = 100, ellipsis = false) => {
  const tagRegex = /(<([^>]+)>)/gi;
  const squareBracketsRegex = /(\[.*?\])/gi;
  const withoutSqB = content.replace(squareBracketsRegex, '');
  const startIndex = withoutSqB.indexOf('<p>');
  const endIndex = withoutSqB.indexOf('</p>');
  var sanitizedPreviewText = withoutSqB.slice(startIndex, endIndex);

  if (ellipsis) {
    sanitizedPreviewText =
      sanitizedPreviewText.replace(tagRegex, '').substr(0, maxLength) + '...';
  }

  return sanitizedPreviewText;
};

export const shortText = (content, maxLength = 100, ellipsis = false) => {
  var shortedText = content.substr(0, maxLength);

  if (ellipsis) {
    shortedText = shortedText + '...';
  }

  return shortedText;
};

export const htmlToText = (content, maxLength = 100, ellipsis = false) => {
  if(!content){
    return null;
  }
  const tagRegex = /(<([^>]+)>)/gi;
  const squareBracketsRegex = /(\[.*?\])/gi;
  const withoutSqB = content.replace(squareBracketsRegex, '');
  const withoutTag = withoutSqB.replace(tagRegex, '');
  var sanitizedPreviewText = withoutTag.slice(0, maxLength);

  if (ellipsis) {
    sanitizedPreviewText = sanitizedPreviewText + '...';
  }

  return sanitizedPreviewText;
};

export default decoderPreviewText;
