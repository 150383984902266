import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from "linaria/react";
import posed from 'react-pose';

const Nav = styled('nav')`
  width: 100%;
  button {
    background: none;
    color: #777;
  }
`;

const PosedMenuContainer = styled(
  posed('div')({
    enter: {
      marginTop: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-50px',
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)``;

export function CategoryMenu({ data }) {
  return (
    <Nav>
      <PosedMenuContainer key={'menuContainerPosed'}>
        <CategoryMenuContainer queryData={data} />
      </PosedMenuContainer>
    </Nav>
  );
}
