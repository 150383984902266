import React, { useState } from 'react';
import ReactModal from 'react-modal';
import SubscribeToStockNotifications from '@jetshop/core/components/Mutation/SubscribeToStockNotifications';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import t, { Intl } from '@jetshop/intl';
import { styled } from "linaria/react";
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { ReactComponent as SendIcon } from '../../svg/Paper-plane.svg';

import { theme } from "../Theme";

const InfoModalWrapper = styled(ReactModal)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ModalInside = styled('div')`
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  background: #fff;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #eae5dc;
  padding-bottom: 1rem;
  box-shadow: 0 0 20px rgba(234, 229, 220, 0.8);

  svg.close-icon {
      cursor: pointer;
    position: absolute;
    top: 23px;
    right: 20px;
    width: 18px;
    height: 18px;
    color: black;
    use,
    g,
    path {
      fill: black;
    }
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    letter-spacing: .03em;
    font-weight: normal;
    padding: 0;
    padding-top
    3px;
  }
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    ${theme.below.lg} {
      font-size: 1rem;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Title = styled('h2')`
  margin-top: 2rem;
  margin-bottom: 5px;
  text-align: center;
`;

const InfoText = styled('p')`
  line-height: 18px;
  font-size: 14px;
  margin-bottom: 1rem;
  letter-spacing: 0.03em;
  color: #333333;
`;

const FormWrapper = styled('div')`
  form {
    display: flex;
    border: 1px solid #bfbdbd;
    input {
      flex: 1 1 auto;
      height: 48px;
      background: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 1rem;
      border: none;
      font-family: ${theme.fonts.primary};
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15em;
    }

    input:focus {
      outline: none;
    }

    input::placeholder {
      color: ${theme.colors.lightgrey};
      font-size: 14px;
      font-weight: normal;
      opacity: 1;
      ${theme.below.xl} {
        font-size: 12px;
      }
    }

    button {
      height: 3rem;
      width: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      border: 0;
      padding: 0 0.25rem;
      justify-content: center;
      color: ${theme.colors.white};
      background: ${theme.colors.black};
      border: 1px solid ${theme.colors.black};
      :focus {
        outline: none;
      }
      ${theme.below.sm} {
        width: 60px;
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
`;

const Error = styled('div')`
  color: ${theme.colors.red};
`;

const ErrorWrapper = styled('div')`
  height: 3rem;
  padding: 0.5rem 0;
  font-size: 14px;
`;

const NotifyModal = ({ modalData = null, setModalData }) => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState({ email: '', error: false });

  const handleCompleted = () => {
    setModalData(null);
  };

  const handleError = () => {
    setError(true);
  };

  const handleInputChange = e => {
    setEmail({ email: e.currentTarget.value, error: false });
  };

  const handleSubmit = mutate => e => {
    e.preventDefault();
    mutate(email.email, modalData.articleNumber);
  };

  return (
    <InfoModalWrapper isOpen={modalData !== null}>
      <ModalInside>
        <Cross
          className="close-icon"
          onClick={() => {
            setModalData(null);
          }}
        />
        <Title>
          {t('{optionName} {valueName} is out of stock', {
            optionName: modalData?.option?.name,
            valueName: modalData?.value
          })}
        </Title>
        <InfoText>{t('Notify me when back in stock')}</InfoText>
        <SubscribeToStockNotifications
          onCompleted={e => {
            handleCompleted(e);
          }}
          onError={e => {
            handleError(e);
          }}
        >
          {(mutate, result, errorCode) => {
            return (
              <FormWrapper>
                <form onSubmit={handleSubmit(mutate)}>
                  <Intl>
                    {t => (
                      <>
                        <input
                          data-testid="notify-when-back-input"
                          onChange={e => {
                            handleInputChange(e);
                          }}
                          placeholder={t('Enter email address').toUpperCase()}
                        />
                        <button
                          data-testid="notify-when-back-ok"
                          disabled={email.length === 0}
                          className={'out-of-stock-notify'}
                        >
                          <SendIcon />
                        </button>
                      </>
                    )}
                  </Intl>
                </form>
                <ErrorWrapper>
                  {error && (
                    <Error>
                      {getErrorDetail(result.error).codes.includes(
                        'InvalidEmail'
                      )
                        ? t('Please enter a valid email address.')
                        : t(
                            'Something went wrong. Please check your email and try again.'
                          )}
                    </Error>
                  )}
                </ErrorWrapper>
              </FormWrapper>
            );
          }}
        </SubscribeToStockNotifications>
      </ModalInside>
    </InfoModalWrapper>
  );
};

export default NotifyModal;
