import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { htmlToText } from '../../utils/textSanitizer';
import KlaviyoCategoryQuery from './KlaviyoCategoryQuery.gql';

export const KlaviyoEmbeded = ({ categoryId }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const dictCultureKey = selectedChannel?.language?.culture;
  const { data } = useQuery(KlaviyoCategoryQuery, {
    variables: {
      id: categoryId
    }
  });

  if (!data) return null;

  let klaviyoIdDict;
  try {
    klaviyoIdDict = JSON.parse(htmlToText(data?.category?.content, 500, false));
  } catch (error) {
    console.warn(`Unable to parse JSON content for Klaviyo`);
  }
  let klaviyoId = klaviyoIdDict ? klaviyoIdDict[dictCultureKey] : null;

  //Temporary form id changes. 
  //TODO Remove it and change the klaviyo id's in Category 524.

  if (klaviyoId === 'klaviyo-form-UAn6uG'){
    klaviyoId = 'klaviyo-form-StEEyv'; 
  }
  if (klaviyoId === 'klaviyo-form-ShLQvZ'){
    klaviyoId = 'klaviyo-form-Up67GB'; 
  }
  if (klaviyoId === 'klaviyo-form-UUnMsr'){
    klaviyoId = 'klaviyo-form-UGLD2n'; 
  }


  if (!klaviyoId) {
    return null;
  }

  return <div className={klaviyoId} />;
};
