export default function useHeaderHeight() {
  let topPos = 0;

  if (typeof document !== 'undefined') {
    const topBarHeight =
      document.getElementById('campaign-bar')?.clientHeight ?? 0;
    topPos += document.getElementById('site-header')?.clientHeight ?? 0;
    topPos += topBarHeight;
    if (window.pageYOffset < topBarHeight) {
      topPos -= window.pageYOffset;
    } else if (window.pageYOffset > topBarHeight) {
      topPos -= topBarHeight;
    }
  }

  return topPos;
}
