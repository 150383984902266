import React from 'react';
import { styled } from "linaria/react";
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '../../Price';
import { theme } from '../../Theme';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';

const Container = styled('aside')`
  width: 320px;
  background-color: ${theme.colors.white};
`;

const CheckoutButton = styled.a`
  padding: ${theme.space[2]};
  margin: ${theme.space[1]};
  text-align: center;
  background: ${theme.colors.black};
  color: white !important;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;


const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};

  .close-btn {
    .close-toast {
      width: 16px !important;
      height: 16px !important;
      use {
        fill: #000000;
      }
    }
  }
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;

  .price-wrapper {
    margin-top: 1rem;

    > div {
      display: flex;
    }
  }
`;

const Header = styled('h3')`
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.03em;
  font-weight: 400;
`;

const ProductName = styled('p')`
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
`;

const CloseButton = styled('button')`
  outline: none;
  top: 0px;
  right: 2px;
  position: absolute;
  background: transparent;
`;

const SmallProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  price,
  error
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const items = cart?.items;

  const klaviyoTrack = event => {
    if (typeof window !== undefined && window?._learnq && cart) {
      const KLAV = window._learnq;
      const data = {
        $event_id: `${cart.id}_${Math.floor(Date.now() / 1000)}`,
        $value: cart?.productTotal?.incVat,
        ItemNames: items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Categories: [
          ...new Set(
            items?.flatMap(item =>
              item?.product?.categories?.flatMap(category => category?.name)
            )
          )
        ],
        Items: items?.flatMap(item => ({
          ProductID: item?.product?.id,
          SKU: item?.product?.articleNumber,
          ProductName: item?.product?.name,
          Quantity: item?.quantity,
          ProductCategories: [
            ...new Set(
              item?.product?.categories?.flatMap(category => category?.name)
            )
          ],
          ItemPrice: item?.product?.price?.incVat,
          RowTotal: item?.total?.incVat,
          ProductURL:
            window.location.origin + item?.product?.primaryRoute?.path,
          ImageURL:
            item?.product?.images?.[0]?.url +
            `?timestamp=${item?.product?.images?.[0]?.modifiedDate}`
        }))
      };
      KLAV?.push(['track', 'Started Checkout', data]);
    }
  };

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image?.url}
                sizes={'800'}
                aspect={'13:17'}
                alt={image?.alt}
                modifiedDate={image?.modifiedDate}
                critical
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('Added {quantity} items to bag', { quantity })}
              </Header>
            )}
            <ProductName>{product.name}</ProductName>

            <div className="price-wrapper">
              <Price
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </div>
          </ProductDetails>
          <CloseButton className="close-btn" onClick={closeToast}>
            <Cross className={'close-toast'} />
          </CloseButton>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={event => {
                  klaviyoTrack(event);
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};

export default SmallProductToast;
