import React, { Fragment, useState, useEffect } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import { styled } from "linaria/react";
import { css } from "linaria";
import { theme } from "../Theme";
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';
import Badge from '../ui/Badge';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  margin-left: 1rem;
  :hover {
    color: ${theme.colors.black};
    svg {
      use {
        fill: ${theme.colors.black};
      }
    }
  }
  svg {
    height: 24px;
    width: 22px;
    path {
      stroke: black;
    }
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme.above.sm} {
    width: unset;
  }
`;

const CartIconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    height: 20px;
    width: 20px;
    margin: 0;
  }
`;

const AddedToCartTracking = ({ qty, cart }) => {
  const [currentQty, setCurrentQty] = useState(0);

  useEffect(() => {
    if (qty > currentQty && typeof window !== undefined) {
      const KLAV = window._learnq;
      const cartData = {
        $value: cart?.productTotal?.incVat,
        ItemNames: cart?.items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Items: cart?.items?.map(product => ({
          ProductName: product?.product?.name,
          ProductID: product?.product?.id,
          SKU: product?.product?.articleNumber,
          Quantity: product?.quantity,
          ItemPrice: product?.product?.price?.incVat,
          RowTotal: product?.total?.incVat,
          ProductURL:
            window.location.origin + product?.product?.primaryRoute?.path,
          ImageURL:
            product?.product?.images?.[0]?.url +
            `?timestamp=${product?.product?.images?.[0]?.modifiedDate}`,
          ProductCategories: product?.product?.categories?.map(cat => cat?.name)
        }))
      };
      KLAV?.push(['track', 'Added to Cart', cartData]);
    }
    setCurrentQty(qty);
  }, [qty]);
  return null;
};

function CartButton() {
  return (
    <Fragment>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);
          const cart = get(result, 'data.cart');
          return (
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {drawer => (
                <Button
                  data-testid="header-cart"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  {itemsInCart > 0 && (
                    <AddedToCartTracking cart={cart} qty={itemsInCart} />
                  )}
                  <CartIconContainer>
                    {itemsInCart > 0 && <Badge text={itemsInCart} />}
                    {/* <span>{itemsInCart}</span> */}
                    <Cart />
                  </CartIconContainer>
                </Button>
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButton;
