import React from 'react';
import { styled } from "linaria/react";

import { theme } from "../Theme";

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  right: -6px;
  span {
    height: 0.9rem;
    width: 0.9rem;
    border: 0;
    border-radius: 50%;
    font-size: 0.5rem;
    line-height: 0.9rem;
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
    overflow: hidden;
    text-align: center;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <span>{text}</span>
    </Wrapper>
  );
};

export default Badge;
