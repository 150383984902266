import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Link } from 'react-router-dom';

const FooterSectiondWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: baseline;
  padding: 0 40px;
  margin: 40px auto 20px auto;
  flex-grow: 1;
  max-height: 206px;

  &.large {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    box-sizing: content-box;
  }
`;

const SectionTitle = styled('h3')`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding: 0px 0 4px 0;
  color: ${theme.colors.black};
`;

const SectionLink = styled(Link)`
  text-decoration: none;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  padding: 0 40px 4px 0;
  max-width: 160px;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LargeFooterLinks = ({ sections }) => {
  return (
      <>
      {sections.map((section, index) => (
        section.type === 'Footer Section' && (
          <FooterSectiondWrapper
            key={index}
            className={index === 0 ? 'large': ''}>
            <SectionTitle>
              {section.properties[0].value.value}
            </SectionTitle>
            {section.children.map((linkStructure, _index) => {
              return (
                <SectionLink 
                  key={_index}
                  to={linkStructure.properties[1].value.value}>
                  {linkStructure.properties[0].value.value}
                </SectionLink>
              )}
            )}
          </FooterSectiondWrapper>
        )
      ))}          
    </>
  );
}
