import t from '@jetshop/intl';
import React from 'react';
import { css } from "linaria";
import { ProductCard } from '../CategoryPage/ProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { ReactComponent as TrashSVG } from '../../svg/trash.svg';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { theme } from '../Theme';

export function Product({ product, loading, children, setModalData }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (product.isVariant && product.variant.images) {
    productForDisplay.images = product.images;
  }

  return (
    <>
      <ProductCard product={productForDisplay} as="div">
        {product.hasVariants && (
          <div className="select-variant">
            <SelectVariant product={product} setModalData={setModalData} />
          </div>
        )}
        {product.validation.status === 'valid' ? (
          <AddToCart product={product} />
        ) : (
          <InvalidProduct validation={product.validation} />
        )}
        <RemoveFromList
          articleNumber={product.articleNumber}
          variant={product.variant}
        />
      </ProductCard>
    </>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button
      className={removeStyles}
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    >
      <TrashSVG />
    </button>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add'),
    package: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}

const removeStyles = css`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: transparent;
  padding: 0.5em;
  z-index: 2;

  svg {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
    height: 1.25em;
    width: 1.25em;
    color: white;
    path {
      stroke-width: 1.3;
    }
  }
`;

const invalidProductStyles = css`
  background: ${theme.colors.tablegrey};
  padding: 1em;
  text-align: center;
  padding: 1em;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: ${theme.colors.lightgrey};
`;
