import React, { useEffect, useContext } from 'react';
import {useIntl} from '@jetshop/intl';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import LipscoreBadge from './LipscoreBadge';
import { Helmet } from 'react-helmet-async'
import LipscoreCustomScript from './LipscoreCustomScript';

const host = 'https://www.8848altitude.com';

const Widget = ({ type, product }) => {
  const tString = useIntl();
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.defaultLanguage?.culture?.split('-')[0];
  const common = {
    id: 'lipscore-' + type,
    className: 'lipscore-widget',
    buyerLabel: tString('Buyer'),
    productLabel: tString('Product'),
    basedOnXReviews: tString('Based on 0000 reviews'),
    viewMoreReviews: tString('View more reviews'),
    customerLabel: tString('Customer'),
  };
  const productData = {
    productName: product?.name,
    brand: product?.customFields?.filter((item) => item.key === 'varumarke')[0]
      ?.stringValue,
    productId: product?.variants?.values?.length > 0 ? product?.variants.values[0].articleNumber : product?.articleNumber,
    productUrl: product?.primaryRoute.path,
    description: product?.description,
    imageUrl: product?.images && product.images.length && product.images[0].url,
    price: product?.price.price,
    stockStatus: product?.stockStatus?.text,
  };

  switch (type) {
    case 'review-list': //Product Page
      return (
        <div id="lpsc-custom-wrapper" 
          lpsc-custom-buyer={common.buyerLabel}
          lpsc-custom-product={common.productLabel}
          lpsc-product-id={productData.productId}
          lpsc-lang={language}
          data-ls-product-name={productData.productName}
          data-ls-brand={productData.brand}
          data-ls-product-id={productData.productId}
          data-ls-product-url={host + productData.productUrl}
          data-ls-image-url={productData.imageUrl}
          data-ls-description={productData.description}
          data-ls-price={productData.price}
          data-ls-price-currency="SEK"
          data-ls-availability={productData.stockStatus}
        >
          <div className="lpsc-custom-badge">
            <div className="custom-badge-score"></div>
            <div className="custom-badge-stars"></div>
            <div className="custom-badge-text">{common.basedOnXReviews}</div>
          </div>
          <div id="lpsc-custom-list"></div>
          <div className="lpsc-custom-list-footer">
            <a id="lpsc-custom-load-more" style={{display: 'none'}}>Load More Reviews</a>
            <LipscoreBadge />
          </div>
        </div>
      );
      case 'service-reviews': //Store review page
        return (
          <div id="lpsc-custom-wrapper" class="lpsc-custom-service-wrapper" lpsc-custom-buyer={common.customerLabel} lpsc-product-id="service_review" lpsc-custom-product="Product" lpsc-lang={language}>
            <div class="lpsc-custom-badge">
              <div class="custom-badge-score"></div>
              <div class="custom-badge-stars"></div>
              <div class="custom-badge-text">{common.basedOnXReviews}</div>
            </div>
            <div id="lpsc-custom-list"></div>
            <div class="lpsc-custom-list-footer">
              <a id="lpsc-custom-load-more" style={{display: 'none'}}>{common.viewMoreReviews}</a>
              <LipscoreBadge />
            </div>
          </div>
        );
    default:
      return (
        null
      );
  }
};


const LipscoreWidget = ({ type, product = null }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.lipscore) {
      window.lipscore.initWidgets();
    }
    LipscoreCustomScript();
  }, [type]);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/lipscore-style.css" />
      </Helmet>
      <Widget type={type} product={product} />;
    </>
  )
};

export default LipscoreWidget;
