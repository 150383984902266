import React from 'react';
import { ReactComponent as SendIcon } from '../../svg/Paper-plane.svg';
import { styled } from "linaria/react";
import t from '@jetshop/intl';

const NotifyWrapper = styled('div')`
  margin-left: auto;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 3px;
  padding: 1px 7px;
  font-size: 12px;
  color: black;
  svg {
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }
`;

const NotifyWhenOutOfStock = ({ setModalData, ...props }) => {
  const updateData = () => {
    if (props.option.name && props.articleNumber) {
      setModalData({ ...props });
    }
  };

  return (
    <NotifyWrapper className={'out-of-stock-notification'} onClick={updateData}>
      <SendIcon />
      <p>{t('notify')}</p>
    </NotifyWrapper>
  );
};

export default NotifyWhenOutOfStock;
