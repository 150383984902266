import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { theme } from "../Theme";
import get from 'lodash.get';
import React from 'react';
import { styled } from "linaria/react";
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import FreeShipping from './FreeShipping';


const Flyout = styled('div')`
  color: ${theme.colors.darkgrey};
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
`;

const LightText = styled('span')``;

const Header = styled('header')`
  text-align: left;
  padding: 1.5rem 0 1rem;
  margin: 0 1rem;
  border-bottom: 1px solid ${theme.colors.lightgrey_previous};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${theme.colors.darkgrey};
  flex-grow: 0;

  > span {
    display: block;
    margin-top: 12px;
    color: ${theme.colors.darkgrey};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
  }

  h2 {
    font-weight: 300;
  }
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  padding: 0 1rem;
  flex-grow: 1;
  overflow-y: auto;
`;

const SummaryWrapper = styled('div')`
  background: ${theme.colors.creme};
  flex-grow: 0;
  padding: 0 0 1.5rem;
`;

const Summary = styled('section')`
  padding: 1.5rem 1rem 0;
  ${theme.below.sm} {
    //padding: 1rem;
    &:first-of-type {
      padding-bottom: 0;
    }
  }

  a {
    background: ${theme.colors.black};
    color: ${theme.colors.white};
    text-decoration: none;
    padding: 0.6rem 0.8rem;
    text-transform: uppercase;
    font-size: 14px;
    transition: all, 0.2s ease;
    letter-spacing: 0.15em;
    border-radius: 0;
    width: 100%;
    font-weight: 300;
    :hover {
      color: white;
    }
  }

  h2 {
    color: ${theme.colors.darkgrey};
    margin-bottom: 1em;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.lightgrey_previous};

    .shipping,
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 16px;
      line-height: 21px;
      font-weight: 300;
      color: ${theme.colors.darkgrey};
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled.a`
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;


const NoItemsWrapper = styled('div')`
  padding: 2em;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${theme.colors.darkgrey};
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = get(result, 'data.cart.items', []);
  const itemCount = get(result, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');
  const discounts = get(result, 'data.cart.aggregatedDiscounts', []);
  const cart = get(result, 'data.cart');
  const track = useTracker();

  const klaviyoTrack = event => {
    if (typeof window !== undefined && window?._learnq && cart) {
      const KLAV = window._learnq;
      const data = {
        $event_id: `${cart.id}_${Math.floor(Date.now() / 1000)}`,
        $value: cart?.productTotal?.incVat,
        ItemNames: items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Categories: [
          ...new Set(
            items?.flatMap(item =>
              item?.product?.categories?.flatMap(category => category?.name)
            )
          )
        ],
        Items: items?.flatMap(item => ({
          ProductID: item?.product?.id,
          SKU: item?.product?.articleNumber,
          ProductName: item?.product?.name,
          Quantity: item?.quantity,
          ProductCategories: [
            ...new Set(
              item?.product?.categories?.flatMap(category => category?.name)
            )
          ],
          ItemPrice: item?.product?.price?.incVat,
          RowTotal: item?.total?.incVat,
          ProductURL:
            window.location.origin + item?.product?.primaryRoute?.path,
          ImageURL:
            item?.product?.images?.[0]?.url +
            `?timestamp=${item?.product?.images?.[0]?.modifiedDate}`
        }))
      };
      KLAV?.push(['track', 'Started Checkout', data]);
    }
  };

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <NoItemsWrapper>{t('No items in cart.')}</NoItemsWrapper>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Header>
        <h2>{t('Your cart')}</h2>
        <ItemCount count={itemCount} />
      </Header>

      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <SummaryWrapper>
        <Summary>
          {discounts.length > 0 && (
            <div className="discounts">
              <h2>{t('Cart total')}</h2>
              <Flex>
                <label>{t('Order value')}</label>
                <Price price={result.data.cart.productPreviousTotal} />
              </Flex>
              <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
              {discounts.map(discount => {
                return (
                  <Flex key={discount.name} style={{ marginTop: '1em' }}>
                    <label>{discount.name}</label>
                    <Price price={discount.value} style={{ display: 'inline' }} />
                  </Flex>
                );
              })}
            </div>
          )}
          <div className="cart-total">
            <FreeShipping
              className="shipping"
              cartTotal={result.data.cart.productTotal}
            />

            <div className="total">
              <label>{t('Total')}</label>
              <Price price={result.data.cart.productTotal} />
            </div>
          </div>
        </Summary>
        <Summary>
          {checkoutUrl && (
            <Checkout
              href={checkoutUrl}
              onClick={e => {
                klaviyoTrack(e);
                track(trackCartCheckoutEvent({ cart: result.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
        </Summary>
      </SummaryWrapper>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer size={300} isOpen={drawer.isOpen} right>
              <CartFlyoutView modal={drawer} result={result} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
