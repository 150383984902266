import "@jetshop/ui/Theme/sanitizeCss";
import '../globalStyles';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async'
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPage from './CategoryPage/CategoryPage';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import { FaviconSelector } from './Favicon';
import { LipscoreAuth } from "./Lipscore/LipscoreAuth";

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage/NotFoundPage.loadable'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});
const LoadableBlogPage = loadable(() => import('./Blog/BlogPage'), {
  fallback: <LoadingPage />
});
const LoadableSingleBlog = loadable(() => import('./Blog/SingleBlogPost'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LipscoreAuth />
          <LoadingBar />
          <CustomFont primaryFont={theme.fonts.primary} injectCss={loadFontCss} />
          <Helmet
            titleTemplate="%s - 8848 Altitude"
            defaultTitle="8848 Altitude"
          />
          <ProductListProvider queries={productListQueries}>
            <Header />
            <Content>
              <PaginationProvider defaultProductsPerPage={36}>
                <Switch>
                  <Route exact path="/">
                    <LoadableStartPage />
                  </Route>
                  <Route path="/favourites">
                    <Favourites />
                  </Route>
                  <Route
                    path="/inside/:lvl2/:lvl3"
                    component={LoadableSingleBlog}
                  />
                  <Route path="/inside" component={LoadableBlogPage} />
                  <Route
                    path="/service/:lvl2/:lvl3"
                    component={LoadableSingleBlog}
                  />
                  <Route path={routes.search.path}>
                    <LoadableSearchPage />
                  </Route>
                  <Route path={routes.signup.path}>
                    <LoadableSignUpPage />
                  </Route>
                  <Route path={routes.login.path}>
                    <LogInPage />
                  </Route>
                  <Route path={routes.stores.path}>
                    <StoreLocator />
                  </Route>
                  <Route path={`${routes.store.path}/:id`}>
                    <Store />
                  </Route>
                  <Route path={routes.tree.path}>
                    <NavTreePage />
                  </Route>
                  <Route path={routes.myPages.path}>
                    <LoadableMyPages />
                  </Route>
                  <Route exact path={routes.forgotPassword.path}>
                    <ForgotPassword />
                  </Route>
                  <Route path={`${routes.resetPassword.path}/:token`}>
                    <ResetPassword />
                  </Route>
                  {/*<Route */}
                  {/*  path="/customer-support/:section" */}
                  {/*  component={CustomerSupportPage} */}
                  {/*/>*/}
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={CategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={CategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Footer />
          </ProductListProvider>
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={[
              'sortOrderChange',
              'filterChange',
              'paginationChange'
            ]}
          />
          <FaviconSelector
            lightIconPath={'/favicon_white.svg'}
            darkIconPath={'/favicon_black.svg'}
          />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
