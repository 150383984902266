import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableContentCollectionPage = loadable(
  () => import('../ContentCollection/ContentCollectionPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableBlogContentPage = loadable(
  () => import('../ContentCollection/BlogContentPage'),
  {
    fallback: <LoadingPage />
  }
);



const CategoryPage = props => {

  const isContentCollectionPage = props?.category?.id === 612 || props?.category?.id === 613;
  const isBlogContentPage = props?.category?.data?.items?.[0]?.type === 'MountainStyleHeader';

  // If page is Mountain Style/Sustainability, render ContentCollectionPage instead
  return isContentCollectionPage ?
    <LoadableContentCollectionPage {...props} /> :
    isBlogContentPage ? <LoadableBlogContentPage {...props} /> :
      <LoadableWindowedCategoryPage {...props} />;
};

export default CategoryPage;
