import { useScript } from '../../../src/hooks/useScript';

export function LipscoreAuth() {
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function () {
      window.lipscore.init({
        apiKey: 'ecceac903845087ed75b8594',
      });
    };
  }
  useScript('//static.lipscore.com/assets/se/lipscore-v1.js');

  return null;
}
