import React from 'react';
import { styled } from "linaria/react";
import t from '@jetshop/intl';
import ProductTabs from './ProductTabs';
import MaxWidth from '../Layout/MaxWidth';
import GradingSection from './GradingSection';
import { theme } from "../Theme";

const Wrapper = styled(MaxWidth)`
  width: 100%;
  display: flex;
  flex-direction: row;

  a {
    color: #333;
  }
`;

const MaterialIconsWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0;
  flex-wrap: wrap;

  img {
    height: 80px;
    margin: 10px 30px 10px 0;
  }
`;

export const SectionHeading = styled('h2')`
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 0.25em;
`;

export const ProductSection = styled('section')`
  line-height: 1.5;

  width: 100%;
  padding-bottom: 3rem;

  ${theme.below.md} {
    margin: 0;
    padding-bottom: 0;
  }

  ${theme.above.md} {
    width: 100%;
  }
  ${theme.above.lg} {
    :nth-of-type(odd) {
      flex: 5 1;

      .image-gallery-content {
        max-width: 500px;
        margin: 0 auto;
      }
    }
    :nth-of-type(even) {
      flex: 2 1;
      height: fit-content;
      position: relative;
      top: 0;
    }
  }
  ul.payment-options-list {
    font-size: 16px;
    font-weight: 400;
  }

  .klarna-img {
    width: 100%;
  }
`;

const ProductInfo = ({
  product,
  fit,
  material,
  details,
  productInfo,
  activeIndex,
  setActiveTab,
  biking,
  breathability,
  casualWear,
  durability,
  freerideSkiing,
  insulation,
  lightweight,
  mountaineering,
  outdoorAndHiking,
  pisteSkiing,
  skiTouring,
  fitMeasure,
  training,
  waterproofness,
  windproofness,
  sizeGuide,
  materialIcons,
  selectedVariation = null
}) => {
  return (
    <Wrapper>
      <ProductTabs
        activeIndex={activeIndex ? activeIndex : '1'}
        setActiveTab={setActiveTab}
      >
        {productInfo &&
        productInfo.htmlValue &&
        productInfo.htmlValue.length > 0 ? (
          <div index="1" label={t('Product information')}>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: productInfo.htmlValue
                }}
              />
            </div>
            <GradingSection
              biking={biking}
              breathability={breathability}
              casualWear={casualWear}
              durability={durability}
              freerideSkiing={freerideSkiing}
              insulation={insulation}
              lightweight={lightweight}
              mountaineering={mountaineering}
              outdoorAndHiking={outdoorAndHiking}
              pisteSkiing={pisteSkiing}
              skiTouring={skiTouring}
              fitMeasure={fitMeasure}
              training={training}
              waterproofness={waterproofness}
              windproofness={windproofness}
              sizeGuide={sizeGuide}
            />
          </div>
        ) : (
          <div index="1" label={t('Product information')}>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: product.description
                }}
              />
            </div>
          </div>
        )}

        {material && material.listValues && material.listValues.length > 0 ? (
          <div index="3" label={t('Material')}>
            <div>
              <ul>
                {material.listValues.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            {materialIcons && materialIcons.htmlValue.length > 0 && (
              <MaterialIconsWrapper
                dangerouslySetInnerHTML={{
                  __html: materialIcons.htmlValue
                }}
              />
            )}
          </div>
        ) : null}

        {details && details.listValues && details.listValues.length > 0 ? (
          <div index="4" label={t('Details')}>
            <div>
              <ul>
                {details.listValues.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
        {/*Costumer doesnt want to use this one, 2020-08-26 */}
        {/* {fit && fit.htmlValue && fit.htmlValue.length > 0 ? (
          <div index="2" label={t('Fit')}>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: fit.htmlValue
                }}
              />
            </div>
          </div>
        ) : null} */}
      </ProductTabs>
    </Wrapper>
  );
};

export default ProductInfo;
