import React from 'react';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { LargeFooterLinks } from './LargeFooterLinks';
import { SmallFooterLinks } from './SmallFooterLinks';

export const FooterLinks = ({ result, isStaticMobileMenu }) => {
  const sections = result.content?.content?.[0]?.items;
  return sections ? (
    <>
      <Above breakpoint="md">
      {matches =>
        matches && (
          <LargeFooterLinks sections={sections} />
        )}
      </Above>
      <Below breakpoint="md">
        {matches =>
          matches && (
            <SmallFooterLinks 
              sections={sections} 
              isStaticMobileMenu={isStaticMobileMenu}/>
          )
        }
      </Below>
    </>
  ) : null;
}
