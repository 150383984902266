import { styled } from "linaria/react";

import { theme } from "../../Theme";

export default styled('main')`
  background: #fff;
  flex: 1 1 auto;
  padding-bottom: 2rem;

  ${theme.below.sm} {
    padding-bottom: 11px;
  }
`;
