import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Trash } from '../../svg/trash.svg';
import { theme } from "../Theme";
import React from 'react';
import { styled } from "linaria/react";
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  align-items: center;

  > :first-child {
    flex: 1 1 20%;
  }
  > :last-child {
    flex: 1 1 80%;
    padding: 1rem 0.5rem;
    ${theme.below.sm} {
      padding-top: 0;
    }
  }
`;

const ProductName = styled('div')`
  .attribute {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: ${theme.colors.darkgrey};
  }

  > a {
    text-decoration: none;

    > h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: ${theme.colors.darkgrey};
    }
  }

  ul {
    margin-top: 3px;
    ${theme.below.sm} {
      margin-bottom: 4px;
    }
  }
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  grid-area: topright;
  text-align: right;
  white-space: nowrap;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  padding-left: 5px;
  color: ${theme.colors.darkgrey};
`;

const AdjustQty = styled('div')`
  display: flex;
  grid-area: bottomleft;

  /* Plus/minus buttons */
  button {
    background: ${theme.colors.white};
    color: black;
    line-height: 21px;
    font-size: 16px;
    height: 21px;
    width: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 1px solid #b9b9b933;
  }

  [disabled] {
    color: ${theme.colors.lightgrey};
  }

  /* Count */
  span {
    margin: 0 8px;
    display: inline-block;
    text-align: center;
    line-height: 21px;
    font-size: 16px;
    height: 21px;
    width: 21px;
    justify-content: center;
    align-items: center;
  }
`;

const RemoveItem = styled('div')`
  grid-area: bottomright;
  text-align: right;

  button {
    padding: 0;
    width: fit-content;
    height: fit-content;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 16px;
    width: 16px;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image
            aspect="13:17"
            sizes="5rem"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
            modifiedDate={
              hasVariantImage
                ? variantImage?.modifiedDate
                : item?.product?.images?.[0]?.modifiedDate
            }
            quality={100}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item.product.primaryRoute.path}>
                  <h2 data-testid="item-name">{item.product.name}</h2>
                  {isVariant && (
                    <ul
                      style={{
                        marginTop: "0"
                      }}
                    >
                      {variantNamesValues.map(
                        (variantOptionNameValue, index) => (
                          <li className="attribute" key={index}>
                            {variantOptionNameValue}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                  {item.configurations.length > 0 && (
                    <ul
                      style={{
                        marginTop: "0"
                      }}
                    >
                      {item.configurations.map(
                        ({ option: { name } }, index) => (
                          <li className="attribute" key={index}>
                            {name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </Link>
              </ProductName>
            )}
          </FlyoutTrigger>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Trash />
          </button>
        </RemoveItem>

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
