import React, { useContext, createContext } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { ReactComponent as Caret } from '../../../svg/Caret.svg';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';

const AccordionFieldWrapper = styled('div')`
  max-width: 44rem;
  padding: 0 20px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCaret = styled(Caret)`
  align-self: center;
  color: #787878;
  transition: all 0.2s ease-in-out;

  &.open {
    transform: rotate(-90deg);
  }
  &.closed {
    transform: rotate(0deg);
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
  display: flex;
`;

const AccordionTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 2px 0;
`;

const SectionTitle = styled('h3')`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  letter-spacing: 0.03em;
  margin-bottom: 5px;
  color: ${theme.colors.black};
`;

const SectionLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  padding: 4px 0 12px 0;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const SmallFooterLinks = ({ sections, isStaticMobileMenu }) => {
  return (
    <AccordionFieldWrapper>
      <Accordion single>
        {({ openIndexes, handleClick, AccordionContent }) => (
          isStaticMobileMenu ? (
            <AccordionContext.Provider
            key={0}
            value={{
              index: 0,
              openIndexes: openIndexes ?? 0,
              handleClick: handleClick,
              accordionContent: AccordionContent
            }}
          >
            <FooterAccordionItem section={sections[0]} />
          </AccordionContext.Provider>
          ) : (
            <>
            {sections.map((section, index) => (
              section.type === 'Footer Section' && (
                <AccordionContext.Provider
                  key={index}
                  value={{
                    index: index,
                    openIndexes: openIndexes ?? 0,
                    handleClick: handleClick,
                    accordionContent: AccordionContent
                  }}
                >
                  <FooterAccordionItem section={section} />
                </AccordionContext.Provider>
              )
            ))}
          </>
          )
        )}
      </Accordion>
    </AccordionFieldWrapper>
  );
};

export const AccordionContext = createContext();

export const FooterAccordionItem = ({ section }) => {
  const accordionContext = useContext(AccordionContext);
  const AccordionIndicator = ({ isOpen }) => (
    <IndicatorIcon>
      {<StyledCaret className={cx(!isOpen ? 'open' : 'closed')} />}
    </IndicatorIcon>
  );

  return (
    <section key={accordionContext.index}>
      <AccordionTitle
        onClick={() => accordionContext.handleClick(accordionContext.index)}>
        <SectionTitle>
          {section.properties[0].value.value}
        </SectionTitle>
        <AccordionIndicator
          isOpen={accordionContext.openIndexes.includes(accordionContext.index)}
        />
      </AccordionTitle>

      <accordionContext.accordionContent
        isOpen={accordionContext.openIndexes.includes(accordionContext.index)}
      >
      {section.children.map((footerLink, index) => {
        return (
          <SectionLink 
          key={index}
          to={footerLink.properties[1].value.value}>
          {footerLink.properties[0].value.value}
        </SectionLink>
        )
      })}
      </accordionContext.accordionContent>
    </section>
  );
};
